@import 'variables';

.draft-clock {
    display: flex;
    align-items: center;
    margin-top: 1.8rem;

    &__paused-info {
        color: $primary-blue;
        font-size: 2.4rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__on-the-clock, &__up-next {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        padding: .4rem .8rem;
        color: #fff;
        letter-spacing: -.028rem;
        border-radius: .5rem;
        height: 3rem;
    }

    &__on-the-clock {
        border: .1rem solid $secondary-purple;
        background-color: rgba(168, 56, 255, .1);
    }

    &__up-next {
        border: .1rem solid $primary-blue;
        background-color: rgba(15, 183, 255, .1);
        margin-left: .8rem;
    }

    &__user-name {
        font-weight: 600;
        padding-left: .4rem;
    }
}
