@import '../../variables.scss';

.draft-header {
    box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
    border-radius: .8rem;
    background: $navigation-gradient;
    padding: 2.4rem;


    &__core {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__detail-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
            color: #fff;
            font-family: $logo-font-family;
            font-size: 3.2rem;
            font-weight: bold;
            letter-spacing: -.064rem;
            margin: 0;
        }
    }

    &__edit-mode-wrapper {
        .header {
            color: $primary-grey;
            font-family: $logo-font-family;
            font-weight: bold;
            text-transform: none;

            span {
                display: block;
                font-weight: normal;
                margin-top: .4rem;
                font-size: 1.4rem;
            }
        }
    }

    &__mobile-actions {
        display: none;
    }

    .button {
        &:last-of-type {
            margin-top: 1.2rem;
        }
    }
}

@media screen and (max-width: 470px) {
    .draft-header {
        &__mobile-actions {
            display: block;
            padding-top: 1.2rem;

            .button {
                padding: .4rem;
            }
        }

        &__time-wrapper {
            button {
                display: none;
            }
        }
    }
}
