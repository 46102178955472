@import 'variables';

.stat-player-pool {
    margin-top: 1.6rem;
    overflow: hidden;
    max-width: 100vw;

    &--focus & {
        &__search-box {
            border-color: $primary-blue;
        }
    }

    .player-pool {

        &__header {
            flex-direction: column;
        }
    }

    &__list {
        overflow: auto;
        width: 100%;
    }

    .stat-table {
        width:100%;
        margin:auto;
        position: relative;
        border-collapse: collapse;
        border-spacing: 0;
        color: $primary-dark;
        overflow: auto;

        .player-team-pos-info {
            &__team-name {
                display: none;
            }

            &__short-team-name {
                display: block;
            }
        }

        &__head {
            th {
                background: #eee;
                font-weight: bold;
                text-align: center;
                padding: .6rem;
                min-width: 4rem;

                &:first-of-type{
                    min-width: 0;
                }

                &:nth-of-type(2) {
                    text-align: left;
                }
            }
        }

        &__fixed-column {
            position: sticky;
            left: 0;
            background: #fff;
        }

        tbody tr  {
            background: #fff;
            padding: .8rem 0;
            border: .1rem solid $border-color;
            cursor: pointer;

            td {
                padding: .6rem;
            }
        }

        &__data-point {
            min-width: 3.6rem;
            font-size: 1.4rem;
            text-align: center;
        }
    }
}

