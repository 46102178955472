@import 'variables';

.league-joiner {
    display: flex;
    flex-direction: column;
    background: $home-gradient;
    min-height: calc(100vh - 6.4rem - 3.2rem);

    .header {
        font-size: 2.8rem;
    }
}
