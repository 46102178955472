@import 'variables';

.player-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-radius: .3rem;
    width: 100%;
    padding: 1rem 1.2rem;
    margin: .4rem 0;

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &--trade {
        background: $primary-purple;
    }

    &--waiver {
        background: $secondary-purple;
    }

    &--substitute {
        background: $secondary-grey;
    }

    &__text {
        color: #fff;
        flex: 1 0 auto;
        font-size: 1.4rem;
        font-weight: bold;
    }

    &__icon {
        max-width: 1.7rem;
    }
}

