@import 'variables';

.records {
    overflow-x: hidden;

    &__heading {
        position: relative;
        margin: 0 1.6rem;
    }

    &__header {
        font-family: $logo-font-family;
        font-size: 3.6rem;
        font-weight: bold;
        letter-spacing: -0.072rem;
        margin: 1.6rem 0;
        padding-top: 2.4rem;
    }

    &__svg {
        position: absolute;
        top: -2.4rem;
        right: -1.6rem;
        z-index: -1;

        svg {
            margin: 0 .4rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &__content {
        background: $primary-grey;
        height: 100%;
        padding: 1.6rem;

        .record {

            &:first-of-type {
                border-top-right-radius: 3.2rem;
            }
        }
    }

    &__options {
        margin: 0;
    }

}
