@import 'variables';

.waivers {
    position: relative;

    &__bubbles {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        opacity: .6;
        overflow: hidden;
        svg {
            position: relative;
            &:first-of-type {
                left: -3rem;
            }
            &:last-of-type {
                right: -3rem;
            }
        }
    }

    &__closed {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 25vh;

        svg {
            width: 4.8rem;
            height: 4.8rem;
        }
    }

    &__heading {
        height: 26.6rem;
        background: $header-gradient;
        position: relative;

        .header {
            color: #fff;
            margin: 0;
            padding: 2.4rem 1.6rem;
            text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
            font-family: $logo-font-family;
            font-size: 3.6rem;
            font-weight: bold;
            line-height: 0.73;
            letter-spacing: -0.072rem;
            text-align: center;
        }
    }
}
