@import '../../variables.scss';

.transfer-confirm {
    margin: 0 .4rem;

    &--trade {
        .button {
            color: $primary-purple;
        }
    }

    &--waiver {
        .button {
            color: $secondary-purple;
        }
    }

    &__header {
        color: $primary-dark;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 1.6rem;
        text-align: center;
    }

    &__player-info {
        background: #fff;
        border-radius: .3rem;
        box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.08);
        margin: 0 .8rem;
    }

    &__player-separator {
        width: calc(100% - 1.6rem);
        height: .2rem;
        background: #eee;
        margin: 0 auto;
    }

    &__cta {
        width: calc(100% - 1.6rem);
        margin: 1.6rem .8rem .8rem;

        .button {
            height: 4.4rem;
        }
    }

    .player-context {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2.4rem 0;

        &__header-text {
            color: $primary-purple;
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 1.2rem;
            text-transform: uppercase;
        }

        &--final {
            .player-context__header-text {
                color: $primary-blue;
            }
        }

        &__player-name {
            color: $primary-dark;
            font-size: 1.6rem;
            font-weight: bold;
        }
    }
}
