/* dm-sans-regular - latin */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    src: local('DM Sans Regular'), local('DMSans-Regular'),
    url('fonts/dm-sans-v5-latin-regular.woff2') format('woff2'),
    url('fonts/dm-sans-v5-latin-regular.woff') format('woff');
}
/* dm-sans-500 - latin */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    src: local('DM Sans Medium'), local('DMSans-Medium'),
    url('fonts/dm-sans-v5-latin-500.woff2') format('woff2'),
    url('fonts/dm-sans-v5-latin-500.woff') format('woff');
}
/* dm-sans-700 - latin */
@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    src: local('DM Sans Bold'), local('DMSans-Bold'),
    url('fonts/dm-sans-v5-latin-700.woff2') format('woff2'),
    url('fonts/dm-sans-v5-latin-700.woff') format('woff');
}
