@import 'variables';

.h2h-fixtures {

    &__header {
        @include header-title;
    }

    &__navigation {
        display: flex;
        justify-content: space-between;
        border-radius: .3rem;
        box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
        border: solid .1rem $primary-border-color;
        width: 6.4rem;
        height: 3.2rem;
        margin-right: 1.6rem;
    }

    &__arrow-container {
        cursor: pointer;
        padding: 1rem;

        svg {
            width: 1.2rem;
            height: 1.2rem;
            fill: $primary-dark;
        }

        svg[disabled] {
            fill: $primary-grey;
            cursor: none;
        }
    }

    &__separator {
        border: .1rem solid $secondary-border-color;
    }

    &__empty {
        font-size: 1.6rem;
        font-weight: bold;
        padding: 1.2rem 0 1.6rem;
        text-align: center;
    }

    &__fixture {
        margin: .8rem 0;

        .fixture.fixture--upcoming {
            flex-direction: row;
        }
    }

    &__fixture-list {
        padding-bottom: 1.6rem;
    }
}

@media screen and (min-width: 768px) {
    .h2h-fixtures {
        &__arrow-container {
            svg {
                transition: fill .25s;
            }

            &:hover svg {
                fill: $primary-grey;
            }
        }
    }
}
