@import 'variables';

.table {
    border-collapse: collapse;
    padding: .8rem;
    width: 100%;

    &__head {
        background: $primary-grey;
        text-align: left;
        text-transform: capitalize;
        color: $primary-dark;

        th {
            font-weight: 500;
        }
    }

    &__header-entry {
        padding: 1rem .8rem 1rem 0;
    }
}

@media screen and (max-width: 374px) {
    .table {
        padding: 0;
    }
}
