@import 'variables';

.draft-priority-card {
    border-radius: .3rem;
    box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.06);
    border: solid .1rem $border-color;
    background-color: #fff;
    margin: 1.2rem 1.6rem;
    padding: 1.2rem;

    input {
        text-align: center;
    }

    input, &__user-name {
        font-size: 1.6rem;
    }

    &__user-name {
        margin-left: .8rem;
        font-weight: bold;
        text-transform: uppercase;
    }
}
