@import 'variables';

.win-percentage {
    position: relative;
    z-index: 1;
    padding: 0 1.6rem 1.6rem;

    &__display {
        background: #541288;
        border-radius: 2.5rem;
        height: 3rem;
        display: flex;
    }

    &__amount {
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
        font-size: 1.4rem;
        font-weight: 800;
        color: #fff;
        background: #541288;
        border-radius: 2.5rem;
        min-width: 3rem;

        &:first-of-type {
            padding-left: 1.2rem;
            text-align: left;

        }

        &:last-of-type {
            padding-right: 1.2rem;
            text-align: right;

        }

        &--loser {
            background: #541288;
            border-radius: 2.5rem;
        }

        &--winner {
            background: $secondary-purple;
            border-radius: 5rem;
        }
    }

    &__trophy {
        position: relative;

        &--draw {
            left: 1.6rem;
        }

        &--homeWin {
            left: -3.2rem;
        }

        &--awayWin {
            right: -3.2rem;
        }
    }

    &__title {
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 0.86;
        letter-spacing: 0.028rem;
        color: #fff;
        margin-bottom: 1.2rem;
    }
}
