@import '../../variables.scss';

.form {
    display: flex;
    flex: 1 0 auto;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0 1.6rem 1.6rem;

    &--create-league {
        .button--submit {
            margin-top: 3.2rem;
        }

        .toggle {
            text-transform: capitalize;
        }
    }

    .toggle {
        width: 100%;
    }

    &__label {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: 1.6rem;
        margin-bottom: .8rem;

        &--radio {
            cursor: pointer;
            font-size: 1rem;
            padding: 0 .8rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0 .8rem;
            flex: 1 0 auto;
        }

        &--active {
            border-color: $primary-purple !important;
            outline: 0 none;
        }
    }

    &__input, &__label--radio {
        font-size: 1.6rem;
        background: rgba(255, 255, 255, 0.1);
        border: .1rem solid $secondary-purple;
        border-radius: .5rem;
        color: #fff;
        height: 4.4rem;
        padding-left: 1rem;
        margin-bottom: .8rem;
        transition: border-color .25s ease-in;

        &:focus, &:hover {
            border-color: $primary-purple !important;
            outline: 0 none;
        }
    }

    &__input {
        width: 100%;

        &--date {
            &::-webkit-calendar-picker-indicator {
                filter: invert(1);
            }
        }
    }

    button {
        width: 100%;
        height: 4.4rem;
        margin-top: auto;
        transition: background-color .25s ease-in;

        &:hover {
            background: $secondary-grey;
        }
    }
}
