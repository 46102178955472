@import '../../variables.scss';

.draft-team {
    border: solid .1rem $border-color;
    border-radius: .8rem;
    box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
    margin-left: 2.4rem;
    width: 32rem;

    &__header {
        background: #f3f6f7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.6rem;
        margin-bottom: 1.6rem;
        border-bottom: .1rem solid $primary-border-color;

        .header {
            margin: 0;
        }
    }

}

@media screen and (max-width: 1280px) {
    .draft-team {
        width: 26rem;
    }
}
