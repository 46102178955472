@import '../../variables.scss';

.transfer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: .2rem;
    box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: .8rem 0;

    &--accepted & {

        &__status {
            background: #35bd8a;
        }

        &__status-context {
            color: #fff;
        }
    }

    &--rejected  & {

        &__status {
            background: #e52552;

        }

        &__status-context {
            color: #fff;
        }
    }

    &--no-cta & {

        &__info {
            &--from {
                padding-top: 1.6rem;
            }
        }
    }

    &--waiver {
        margin: 1.6rem 0;
    }

    &__cta {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        width: 100%;
    }

    &__user-name {
        border-radius: .3rem;
        background: $border-color;
        color: $primary-dark;
        margin: 1.6rem 1.2rem .4rem;
        padding: .8rem 1.6rem;
        font-size: 1.2rem;
        font-weight: 600;

        span {
            font-size: 1.2rem;
            font-weight: normal;
        }
    }

    &__priority {
        margin-left: 1.2rem;
        width: 2.6rem;
        height: 2.6rem;
        background: $primary-dark;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: #fff;
            font-size: 1.4rem;
            font-weight: bold;
        }
    }

    &__info {
        padding-left: 1.2rem;

        &--from {
            .transfer__sub-heading {
                color: $primary-purple;
            }
        }

        &--to {
            padding-bottom: 1.6rem;

            .transfer__sub-heading {
                color: $primary-blue;
            }
        }
    }

    &__player-info {
        display: flex;
        align-items: center;
    }

    &__player-name {
        font-size: 1.6rem;
        font-weight: bold;
        color: $primary-dark;
        margin-top: .4rem;
        margin-right: .8rem;
    }

    &__separator {
        display: flex;
        align-items: center;
        height: 2.4rem;
        width: 100%;
    }

    &__separator-line {
        height: .1rem;
        background: $tertiary-grey;
        width: 100%;
    }

    &__sub-heading {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $tertiary-grey;
        padding: 0 1.2rem;
        height: 4.6rem;
        width: calc(100% - 2.4rem);
        margin: 0 auto 1.6rem;
    }

    &__status-context {
        color: #666666;
        font-size: 1.4rem;
        font-weight: bold;
        padding: 1.6rem 0;
    }

    &__trade-icon {
        border-radius: .1rem;
        border: solid .1rem $tertiary-grey;
        margin-right: 1.2rem;
        padding: .6rem;
    }

    .button {
        margin: 1.6rem;

        &--cancel {
            margin-left: auto;
        }
    }

}
