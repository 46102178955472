@import 'variables';

.create-waiver-pane {

    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: .8rem;
    }
}
