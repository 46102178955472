@import '../../variables.scss';

.spinner {
    height: 10rem;
    width: 20%;
    text-align: center;
    padding: 1rem;
    margin: 0 auto 1rem;
    display: inline-block;
    vertical-align: top;

    svg path,
    svg rect{
        fill: $secondary-purple;
    }
}
