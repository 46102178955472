@import 'variables';

.h2h-streak {
    margin: .8rem 0 1.6rem;

    .streak {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: .3rem .5rem;
        border: .1rem solid #fff;
        border-radius: 50%;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: bold;
        width: 2.2rem;
        margin: 0 .2rem;
        color: #fff;

        &--win {
            background-color: rgba(0, 210, 109, 0.08);
            border-color: #00d26d;
        }

        &--loss {
            background-color: rgba(255, 70, 70, 0.08);
            border-color: #ff4646;
        }
    }
}
