@import 'variables';

.draft-player-card {
    background: #fff;
    padding: .8rem;
    border-radius: .8rem;
    box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
    border-left: .1rem solid #f1f1f1;
    display: flex;
    width: 100%;

    &--pool {
        min-width: 20rem;
        display: flex;
        justify-content: space-between;
        padding: 0;

        .draft-player-card {

            &__last-name {
                max-width: none;
            }

            &__player-info {
                padding: .8rem;
            }

            &__player-team {
                padding: .8rem;

                .player-team {
                    &__svg svg, &__svg img {
                        width: 3.2rem;
                        height: 3.2rem;
                    }
                }
            }
        }
    }

    &--empty {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(97, 45, 247, 0.04);
        min-height: 10.8rem;
    }

    &--wait & {

        &__action[data-action-type="draft"]{
            opacity: .2;
            pointer-events: none;
            touch-action: none;
        }
    }


    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(187, 18, 215, 0.85);
        }
        70% {
            box-shadow: 0 0 0 .7rem rgba(187, 18, 215, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(187,18,215, 0);
        }
    }

    &--animate {
        animation: pulse 2.2s forwards 2;
        position: relative;
    }

    &:first-of-type {
        border-left: none;
    }

    &__player-info {
        flex: 1 0 auto;
    }

    &__pick {
        color: $primary-pink;
        font-size: 1.2rem;
        font-weight: bold;

        &--suggested-rank {
            color: $primary-blue;
            cursor: default;
        }
    }

    &__picking {
        color: $primary-purple;
        font-size: 1.25rem;
        font-style: italic;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__name {
        color: $primary-dark;
        line-height: 1;
        margin-top: .6rem;
    }

    &__first-name {
        font-size: 1.2rem;
        display: block;
        margin-bottom: .2rem;
    }

    &__last-name {
        display: inline-block;
        font-size: 1.4rem;
        font-weight: bold;
        max-width: 7rem;
        @include ellipsis;
    }

    &__team-name {
        display: block;
        color: $secondary-grey;
        font-size: 1.2rem;
        font-weight: 600;
        margin: .6rem 0;
        line-height: .83;
    }

    &__team-mobile {
        display: none;
    }

    &__position {
        color: $primary-dark;
        font-weight: bold;
        padding-top: .6rem;
        text-transform: capitalize;
    }

    &__draft-actions {
        border-left: .1rem solid $border-color;
        display: flex;
        justify-content: space-between;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 4rem;

        svg {
            height: calc(50% - .05rem);
        }
    }

    &__action {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &[data-action-type="info"], &[data-action-type="draft"] {
            position: relative;
            overflow: hidden;

            &:before {
                position: absolute;
                background: transparent;
                content: '';
                width: 100%;
                height: 100%;
                transform: translateX(100%);
                transition: transform .25s ease;
            }

            &:after {
                position: absolute;
                color: #fff;
                content: attr(data-action-type);
                text-transform: uppercase;
                font-weight: bold;
                opacity: 0;
                top: 50%;
                bottom: 0;
                transform: translate(100%, calc(-50% + .9rem));
                transition: opacity .25s .25s, transform .25s ease;
            }

            &:hover {

                &:before {
                    transform: translateX(0);
                }

                &:after {
                    opacity: 1;
                    transform: translate(0, calc(-50% + .9rem));
                }

            }
        }

        &[data-action-type="info"] {
            &:hover {
                &:before {
                    background: $primary-blue;
                }
            }
        }

        &[data-action-type="draft"] {
            &:hover {
                &:before {
                    background: $primary-purple;
                }
            }
        }
    }

    &__manager-info {
        display: none;
    }

    &__player-team {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    &__edit-pick {
        background: none;
        border: 0;
        cursor: pointer;

        &:hover {
            svg path {
                opacity: .75;
            }
        }
    }

    &__short-position {
        display: none;
    }

    &__separator {
        background: $border-color;
        height: .1rem;
        width: 100%;
    }

    .player-team {

        &__svg svg, &__svg img {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
}

@media screen and (max-width: 1280px) {
    .draft-player-card {
        &__last-name {
            max-width: 6rem;
        }
    }
}

@media screen and (max-width: 900px) {

    .draft-player-card {
        .player-team {

            &__svg svg, &__svg img {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }
}
