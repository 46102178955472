@import 'variables';

$min-item-height: 3.5rem;
$min-double-label-item-height: 5.6rem;
$chunky-min-item-height: 4.4rem;

.dropdown {
    position: relative;
    padding-bottom: $min-item-height;

    &--chunky {
        padding-bottom: $chunky-min-item-height;

        .dropdown {
            &__item {
                padding: 1.2rem;
            }

            &__list {
                max-height: $chunky-min-item-height
            }
        }
    }

    &--double-label {
        padding-bottom: $min-double-label-item-height;

        .dropdown {

            &__double-label {
                display: inline-block;
                color: $primary-purple;
                font-size: 1.2rem;
                font-weight: 600;
                margin: 0 4.8rem .2rem 0;
            }

            &__list {
                max-height: $min-double-label-item-height;
            }

            &__item {
                padding-left: 0;
            }
        }
    }

    &--single-option {
        opacity: .5;
        pointer-events: none;
    }

    &--open {
        z-index: 10;
    }

    &__list {
        position: absolute;
        top: 0;
        background: #fff;
        overflow-y: auto;
        width: calc(100% - .2rem);
        margin: -.1rem .1rem .1rem .1rem;
        list-style: none;
        padding: 0 1.2rem;
        will-change: max-height;
        transition: max-height .2s ease-in;
        max-height: $min-item-height;
        font-size: 1.4rem;
        font-weight: 600;
        color: $primary-dark;
        border: 1px solid $primary-border-color;
        border-radius: .5rem;
        box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.08);
    }

    &__name {
        font-size: 1.6rem;
    }

    &__item {
        position: relative;
        font-size: 1.2rem;
        font-weight: normal;
        padding: .8rem;
        cursor: pointer;
        display: none;
        align-items: center;

        &:after {
            position: absolute;
            bottom: 0;
            left: -1.2rem;
            content: '';
            background: $border-color;
            width: calc(100% + 2.4rem);
            height: .1rem;
        }

        &:last-of-type:after {
            content: none;
        }

        &--first {
            display: flex;
            font-size: 1.4rem;
            font-weight: 600;

            .dropdown__name {
                font-size: 1.4rem;
            }

            &:after {
                content: none;
            }
        }

        &--active {
            svg {
                margin-left: auto;
                width: 1.2rem;
            }
        }
    }

    &__separator {
        margin-left: auto;
        margin-right: 1.2rem;
        width: .1rem;
        height: 1.6rem;
        background: $primary-border-color;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .dropdown__list{}
    }

    &--open & {

        &__list {
            max-height: 24rem;
        }

        &__item {
            display: flex;

            &--first {
                &:after {
                    content: '';
                }
            }
        }
    }
}
