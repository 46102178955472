@import 'variables';

.draft-team-players {
    margin: 0 1.6rem 2.4rem;
    user-select: none;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .4rem;
    }

    &__tally, &__type {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: .88;
        text-transform: capitalize;
    }

    &__type {
        color: $primary-dark;
    }

    &__player {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1.4rem 0;
        border-bottom: .1rem solid $border-color;

        &:last-of-type {
            border-bottom: none;
        }


        &--empty-spot {

            .draft-team-players {

                &__draft-position {
                    background: white;
                    border: .1rem solid $secondary-grey;

                    svg {
                        height: 2rem;
                    }
                }

                &__player-name {
                    color: $secondary-grey;
                }
            }
        }
    }

    &__player-name {
        display: block;
        color: $primary-dark;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.14;
        padding-bottom: .4rem;
    }

    &__draft-position {
        border-radius: 50%;
        background: $secondary-grey;
        margin-right: .8rem;
        height: 2.6rem;
        width: 2.6rem;

        span {
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
            padding: 0 .2rem;
            line-height: 2.3rem;
            text-align: center;
            width: 100%;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
