@import 'variables';

.table {

    &__body {

        /** Ensures there is space before the body **/
        &:before {
            content: "-";
            display: block;
            line-height: .2rem;
            color: transparent;
        }

    }

    &__row {
        border-bottom: .1rem solid $secondary-border-color;

        td {
            padding: .4rem;
        }
    }

    &__entry {

        &--clickable {
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &--split-level {

            span {
                padding: 0 .1rem;

                &:nth-child(1) {
                    font-size: 1.2rem;
                    font-weight: 600;
                    display: inline-block;
                    @include ellipsis;
                }

                &:nth-child(2) {
                    color: $secondary-grey;
                    display: block;
                    font-size: 1rem;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }

        }
    }

    &__two-level {
        display: flex;
        align-items: center;
        max-width: 16.5rem;

        &--name {
            align-items: flex-start;
            flex-direction: column;

            span {
                &:nth-child(2) {
                    font-size: 1.2rem;
                    font-weight: 600;
                    display: inline-block;
                    @include ellipsis;
                }

                &:nth-child(3) {
                    color: $secondary-grey;
                    display: block;
                    font-size: 1.2rem;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }

        }
    }
}
