@import 'variables';

.fixture {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
    border: solid .1rem $secondary-border-color;
    background-color: #fff;
    margin: 0 1.8rem;
    cursor: pointer;

    &--active & {

        &__context {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }

        &__outcome-wrapper {
            height: 3.6rem;
            width: 10rem;
            box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
            border: .1rem solid $secondary-border-color;
            border-radius: .3rem;
        }

        &__outcome {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;

            &--away-winner {
                .fixture__score--home {
                    color: $secondary-grey;
                }
            }

            &--home-winner {
                .fixture__score--away {
                    color: $secondary-grey;
                }
            }
        }

        &__score-divider {
            border: .1rem solid $secondary-border-color;
            height: calc(100% - 1.2rem);
            margin: 0;
        }

        &__team {
            width: calc(50vw - 6.8rem);
            &--home, &--away {

            }

            &--home {
                text-align: right;
                padding-right: .8rem;
            }

            &--away {
                padding-left: .8rem;
                text-align: left;
            }
        }

        &__team-name {
            @include ellipsis;
            display: block;
            /** 50vw - Padding to container - half of score box - padding **/
            font-size: 1.2rem;
            font-weight: bold;
            color: $primary-dark;
        }
    }

    &--upcoming & {
        &__outcome-wrapper {
            background: $primary-grey;
            width: 3.8rem;
            height: 3.8rem;
            border-radius: 0 1.6rem 0 1.6rem;
            display: flex;
            margin: 0 1.2rem;
            justify-content: center;
            align-items: center;
        }
    }

    &__context {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .8rem 0;
        width: 100%;
    }

    &__divider {
        height: .1rem;
        margin-left: 1.2rem;
        background-color: $tertiary-grey;
    }

    &__team {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(50% - 3.2rem);

        &--home {
            align-items: flex-end;
        }

        &--away {
            align-items: flex-start;
        }
    }

    &__team-name, &__team-name-short {
        color: $primary-dark;
        font-size: 1.2rem;
        font-weight: 600;
    }

    &__team-name {
        display: none !important;
    }

    &__team-manager {
        font-size: 1.1rem;
        font-weight: 500;
        color: $secondary-grey;
    }

    &__outcome {
        font-size: 1.2rem;
        font-weight: 800;
    }

    &__score {
        width: 5.35rem;
        text-align: center;
    }

    &__details-wrapper {
        border: .1rem solid $secondary-border-color;
        border-radius: .5rem .5rem 0 0;
    }

    &__details {
        border: none;
        background: transparent;
        padding: .6rem 1rem;
        color: $primary-purple;
        font-weight: 800;
        text-transform: uppercase;

        &:hover,&:focus {
            background: none;
            border: none;
        }

        svg {
            margin-right: .2rem;
        }
    }
}

@media screen and (min-width: 500px) {
    .fixture {
        &__team-name {
            display: block !important;
        }

        &__team-name-short {
            display: none !important;
        }
    }
}

@media screen and (min-width: 768px) {
    .fixture {
        transition: border-color .25s ease-in-out;
        &:hover {
            border-color: $secondary-grey;
        }
    }
}
