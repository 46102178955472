@import '../../variables.scss';

.draft-player-pool {
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-areas: ". . . ." ". . . ." ". . . ." ". . . .";
        grid-gap: .8rem;
        margin-top: 1.6rem;
    }
}
