@import '../../variables.scss';

.league-viewer {
    position: relative;

    &__container {
        padding: 0 1.2rem;
    }

    &--no-leagues & {

        &__your-leagues {
            margin: 0 auto;
        }
    }

    &__no-leagues-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;

        p {
            font-size: 1.4rem;
        }
    }

    &__league {
        background: rgba(255, 255, 255, 0.1);
        border: .1rem solid $primary-purple;
        border-radius: .5rem;
        box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.08);
        cursor: pointer;
        padding: 1.2rem;
        margin: .8rem;
        transition: background .25s ease-in, color .25s ease-in;

        &:hover {
            background: $primary-blue;

            .league-viewer {
                &__league-name, &__league-meta {
                    color: #fff;
                }
            }
        }
    }

    &__league-meta {
        display: flex;
        align-items: center;
        color: #919191;
        font-size: 1.2rem;
        margin-top: .4rem
    }

    &__league-name {
        font-size: 1.4rem;
        font-weight: 300;
        color: $primary-dark;
    }

    &__league-season {
        padding-left: 1rem;
        font-weight: bold;
    }

    &__league-type {
        color: $primary-pink;
    }

    &__actions {
        margin-top: 2.4rem;

        .button {
            margin: 1.2rem 0;
            padding: 1.4rem 0;
            justify-content: center;
            width: 100%;

        }
    }

    &__action {
        text-decoration: none;
    }

    .header {
        @include header-title;
        flex-direction: column;
    }

    &__spinner-container {
        position: relative;
        width: calc(100vw + 1.2rem);
        left: -1.2rem;
    }
}
