@import 'variables';

.user-header {
    background-image: $header-gradient;
    height: 23rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__bubbles {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;

        svg {
            position: relative;

            &:first-of-type {
                left: -1.6rem;
                top: 2rem;
                align-self: center;
            }

            &:last-of-type {
                position: static;
                right: -1.6rem;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
    }

    &__header-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;

        .header {
            color: #fff;
            @include ellipsis;
            max-width: calc(100% - 2.4rem);
        }

        svg {
            position: relative;
            right: -1rem;
            top: -1rem;
            cursor: pointer;
        }
    }

    &__points-wrapper {
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
    }

    &__points-title, &__points {
        text-align: center;
    }

    &__points-title {
        color: $primary-blue;
        text-transform: uppercase;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
        font-size: 1rem;
        font-weight: 800;
        letter-spacing: 0.12rem;
    }

    &__points {
        color: #fff;
        font-size: 4.4rem;
        font-weight: 800;
    }
}
