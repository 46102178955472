@import 'variables';

.hero {
    padding: 2.4rem 0;
    position: relative;
    width: 100%;
    background-image: $header-gradient;

    .header {
        font-family: $logo-font-family;
        font-weight: bold;
        letter-spacing: -0.072rem;
        line-height: 1.1;
        text-align: center;
        text-transform: capitalize;
        padding: 0 4.6rem;
        margin: 0;
        color: #fff;

        span {
            display: block;
            font-family: $base-font-family;
            font-size: 1.2rem;
            padding-top: .8rem;
            letter-spacing: normal;
            text-transform: none;
            opacity: .8;
        }
    }

    .button {
        background: $primary-blue;
        display: block;
        margin: 2.4rem auto 0;
        text-align: center;
        &:hover {
            background: $primary-purple;
        }
    }


    &--bubbles {
        background: none;
    }

    &--join-journey {
        position: relative;
        overflow: hidden;

        .hero__visual-bubble {
            position: absolute;
            z-index: 0;
            top: 0;

            &:first-of-type {
                left: 0;
                svg {
                    transform: translate(-35%,0%);
                }
            }

            &:last-of-type {
                right: 0;
                svg {
                    transform: translate(30%,0%);
                }

            }
        }

        .header {
            padding: 2.4rem 1.6rem 0;
        }
    }


    &--with-logo {
        display: flex;
        justify-content: space-between;
        background: #f7f8fb;
        padding: 0;
        overflow: hidden;

        .header {
            padding: 1.8rem 0 0 1.6rem;
            color: $primary-dark;
            font-size: 3.6rem;
        }
    }
}
