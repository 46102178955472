@import 'variables';

.user-top-draft-picks {
    padding: .8rem 0 2.4rem;

    &__header {
        font-family: $logo-font-family;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: .86;
        text-align: center;
        margin-bottom: 1.4rem;
    }
}
