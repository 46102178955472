@import '../../variables.scss';

.user-gw-tally {
    background: $header-gradient;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;

    &__game-week {
        border-radius: 5rem;
        border: solid .1rem #fff;
        background-color: rgba(0, 0, 0, 0.25);
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: .1rem;
        padding: .6rem 1.6rem;
        margin-bottom: .8rem;
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
    }

    &__context-wrapper {
        width: 100%;
        text-align: center;
    }

    &__game-week-wrapper {
        position: relative;
        padding-bottom: 1.6rem;

        &:after {
            position: absolute;
            content: '';
            bottom: 0;
            width: 100%;
            height: .1rem;
            border-radius: .2rem;
            transform: translate(-50%, -50%);
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
        }
    }

    &__description {
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
        font-weight: 800;
        line-height: 1.2;
        letter-spacing: .12rem;
        color: $primary-blue;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    &__game-week-points {
        margin: 1.2rem 0;
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
        font-size: 5.2rem;
        font-weight: 800;
        line-height: 0.77;
    }

    &__game-week-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1.2rem;
    }

    &__points-wrapper {
        display: flex;
        justify-content: center;
        padding: 2.4rem 0 1.2rem;
    }

    &__points-description, &__points {
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
        font-size: 1.4rem;
        line-height: 0.86;
        letter-spacing: normal;
    }

    &__points-description {
        margin: 0;
    }

    &__points {
        font-weight: bold;
    }
}
