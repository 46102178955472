@import 'variables';

.user-next-h2h {
    &__bubbles {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        opacity: .65;

        svg {
            position: relative;
            &:first-of-type {
                left: -2rem;
            }
            &:last-of-type {
                right: -1.6rem;
            }
        }
    }

    &__opponent-context {
        margin: 0 1.6rem;
    }

    &--no-past-data & {
        &__opponent {
            padding-bottom: 1.6rem;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include header-title;
    }

    &__box {
        height: 8.6rem;
        background-image: $navigation-gradient;
        border-radius: .3rem;
        margin: 0 .8rem;
        padding: 0 .8rem;
        position: relative;
    }

    &__opponent {
        padding-top: 2rem;
        text-align: center;
        cursor: pointer;
        font-family: $logo-font-family;

        .header {
            color: $primary-grey;
            margin: 0;
        }

        p {
            color: $secondary-grey;
            font-size: 1.4rem;
            font-weight: bold;
            margin: 0;
        }
    }

    &__opponent-streak {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $secondary-dark;
        border-radius: 8rem;
        height: 2.6rem;
        color: $primary-grey;
        text-align: center;
        width: 50%;
        left: 50%;
        top: 0;
        transform: translate(50%, -50%);

        p, span {
            font-size: 1rem;
            font-weight: bold;
            letter-spacing: .05rem;
            text-transform: uppercase;
        }
    }
}

@media screen and (min-width: 768px) {
    .user-next-h2h {
        &__opponent {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
