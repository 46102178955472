@import 'variables';

.logo {
    position: relative;
    right: -1.8rem;
    top: 1.4rem;

    &__tier {
        &:first-of-type {
            margin-bottom: .8rem;
        }
    }
    svg {
        max-height: 6rem;
        max-width: 6rem;

        &:nth-child(1) {
            margin-right: .8rem;
        }
    }
}
