@import 'variables';

.player-team-pos-info {
    color: $secondary-grey;
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: .4rem;
    text-transform: capitalize;

    &--center {
        justify-content: center;
    }

    &__short-team-name {
        display: none;
    }

    &__team-svg svg, &__team-svg img {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: .4rem;
    }
}

@media screen and (max-width: 400px) {
    .player-team-pos-info {

        &__short-team-name {
            display: block;
        }

        &__team-name {
            display: none;
        }
    }
}
