@import '../../variables.scss';

.home-container {
    margin: 0 auto;
    background: $navigation-gradient;
    position: relative;
    .hero .header {
        font-size: 3.6rem;
    }

    &__explainer {
        font-size: 1.6rem;
    }

    &__gradient {
        position: absolute;
        background-image: linear-gradient(to top, rgba(104, 52, 255, 0), rgba(104, 52, 255, 0), #65086a);
        width: 100%;
        height: 30.5rem;
    }

    &--no-auth {

        .button {
            padding: 1rem;
            width: 20rem;
        }
    }
}
