@import 'variables';

.toggle {
    background: #fff;
    border-radius: .5rem;
    box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
    border: solid .1rem $primary-border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--pagination {
        margin: 3.2rem auto 0;
        width: 21rem;

        .toggle {

            &__option {
                height: 2.6rem;
            }
        }
    }

    &--secondary {
        box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.1);
        border-radius: .4rem;
        border: .1rem solid $border-color;
        height: 3.4rem;
        width: 20rem;

        .toggle {

            &__option {
                position: relative;
                line-height: .75;
                height: auto;

                &:first-of-type {

                    &:after {
                        position: absolute;
                        content: '';
                        top: 0;
                        right: -.5rem;
                        bottom: 0;
                        width: .1rem;
                        height: 1.2rem;
                        background: $border-color;
                    }
                }

                span {
                    font-size: 1.2rem;
                    font-weight: 500;
                }

                &--active {
                    background: none;
                    border-color: transparent;
                    color: $primary-dark;
                }
            }
        }
    }

    &--no-box {
        border: 0;
        box-shadow: none;

        .toggle {

            &__option {
                background-color: #fff;
                border: 0;
                display: block;
                flex: none;
                position: relative;
                margin-bottom: 0;
                height: 3.6rem;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: .6rem;
                    background: transparent;
                    transition: background .25s ease-in;
                }

                &:focus, &:hover {
                    background: #fff;
                    color: $primary-dark;

                    &:after {
                        background: $secondary-purple;
                    }
                }

                &--active {
                    &:after {
                        background: $secondary-purple;
                    }
                }

                span {
                    font-size: 1.6rem;
                }
            }
        }
    }

    &--userTeam {
        margin: 1.6rem 1.6rem 0;
    }

    &__option {
        border-radius: .2rem;
        border: solid .1rem transparent;
        color: $secondary-grey;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        height: 4.8rem;
        margin: .4rem;
        text-align: center;
        transition: background-color .15s ease-in, border-color .15s ease-in, color .15s ease-in;

        span {
            font-size: 1.3rem;
            font-weight: 600;
        }

        &--icon {
            background: $border-color;
        }

        &--disabled {
            background: none;
            pointer-events: none;
            touch-action: none;
        }

        &--active {
            background-color: rgba(113, 14, 134, 0.1);
            border-color: $secondary-purple;
            color: $primary-dark;
        }
    }

    .arrow {
        position: relative;
        width: 1.4rem;

        &--prev {
            left: -.1rem;
            top: -.1rem;
            transform: rotate(90deg);
        }

        &--next {
            right: -.1rem;
            top: -.1rem;
            transform: rotate(-90deg);
        }
    }
}

@media screen and (min-width: 768px) {
    .toggle {

        &--no-box {
            justify-content: flex-start;

            .toggle {
                &__option {

                    &:last-of-type {
                        margin: 0 1.6rem;
                    }
                }
            }
        }

        &--secondary {

            .toggle {

                &__option {

                    &:hover, &:focus {
                        background: none;
                        border-color: transparent;
                        color: $primary-dark;
                    }
                }
            }
        }

        &__option {

            &:hover, &:focus {
                background-color: $secondary-purple;
                color: #fff;
            }
        }
    }
}
