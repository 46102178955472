@import 'variables';

.player-team {
    color: $secondary-grey;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &__team-name {
        display: block;
        font-size: 1.2rem;
        font-weight: 500;
    }

    &__svg svg, &__svg img {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: .4rem;
    }
}
