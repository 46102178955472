@import '../../variables.scss';

.live-scores {

    &__header {
        @include header-title;
    }

    &__day-wrapper {
        padding: 0 1.6rem 1.6rem;
    }

    &__day-header {
        color: $secondary-grey;
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0 0 .8rem;
    }
}
