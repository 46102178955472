$primary-dark: #15191d;
$secondary-dark: #0d0923; //#1e2632; // TODO-UI remove
$primary-blue: #0fb7ff; //#0c9bf5;
$primary-purple: #6834ff; //#bb12d7;
$primary-pink: #e00d83;
$secondary-purple: #a838ff; //#710e86;

$primary-purple-hover: #461cbe;
$primary-pink-hover: #c00e72;
$secondary-purple-hover: #6817a8;

$primary-grey: #f2f3f5; //#7c7c7c;
$secondary-grey: #919aaf; //#414141;
$tertiary-grey: $primary-grey; //#eaeaea;

$primary-border-color: #e7e7ea; //#e5e5e5;
$border-color: $primary-border-color; // TODO-UI remove
$secondary-border-color: #eff3f6;
$border-radius: .2rem;
$base-font-family: 'Inter', sans-serif;
$logo-font-family: 'DM Sans', sans-serif;

$nav-height: 6.4rem;

$background-gradient: linear-gradient(to top, $primary-purple, #4e0f69);
$nav-background-gradient: linear-gradient(to right, #18164e,  #571855);
$header-gradient: linear-gradient(to top, #18164e, #571855);
$navigation-gradient: linear-gradient(to top, #0a0923, #2f0e2d);
$home-gradient: linear-gradient(to right, #0a0923, #571855);
$horizontal-gradient: $background-gradient; // TODO-UI remove
$vertical-gradient: $header-gradient; // TODO-UI remove


@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header {
        margin: 2.4rem 1.6rem 2rem;
    }
}

@mixin row-emphasis {
    color: $primary-dark;
    font-size: 1.2rem;
    font-weight: bold;
}

$ATL: linear-gradient(to right, #6d061b, #ce173e);
$ARI: linear-gradient(to right, #600120, #b20039);
$BAL: linear-gradient(to right, #120a2d, #24135f);
$BUF: linear-gradient(to right, #1a2d62, #00338d);
$CAR: linear-gradient(to right, #235a6e, #3cb6e3);
$CHI: linear-gradient(to right, #832603, #dc4209);
$CIN: linear-gradient(to right, #74250c, #f3511d);
$CLE: linear-gradient(to right, #330f01, #f44d0f);
$DAL: linear-gradient(to right, #131c3c, #263a7b);
$DEN: linear-gradient(to right, #001f48, #ff4f00);
$DET: linear-gradient(to right, #063b70, #0070e0);
$GB: linear-gradient(to right, #0e2824, #034a42);
$HOU: linear-gradient(to right, #00174a, #092a6e);
$IND:  linear-gradient(to right, #092644, #003872);
$JAX: linear-gradient(to right, #073b47, #0086a6);
$KC: linear-gradient(to right, #741623, #e81231);
$LA: linear-gradient(to right, #20222c, #4a4e68);
$LAC: linear-gradient(to right, #2c3c67, #007eca);
$MIA: linear-gradient(to right, #0b4d52, #0aa1ab);
$MIN: linear-gradient(to right, #201035, #532b8c);
$NE: linear-gradient(to right, #02162d, #062e62);
$NO: linear-gradient(to right, #000000, #56473c);
$NYG: linear-gradient(to right, #101c3f, #172d70);
$NYJ: linear-gradient(to right, #04241a, #0a4e31);
$LV: linear-gradient(to right, #100f0f, #424242);
$PHI: linear-gradient(to right, #002429, #004c55);
$PIT: linear-gradient(to right, #060606, #ffbb08);
$SF: linear-gradient(to right, #4c0a0a, #c21312);
$SEA: linear-gradient(to right, #051227, #0a316b);
$TB: linear-gradient(to right, #4e0403, #d80100);
$TEN: linear-gradient(to right, #081f3d, #2c8de4);
$WAS: linear-gradient(to right, #301318, #8a152a);
