@import 'variables';

.season-not-started {
    background: $navigation-gradient;
    height: calc(100% - 3.2rem - #{$nav-height}); //3.2rem is content padding-top
    position: absolute;
    width: 100%;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .header {
        color: #fff;
        text-align: center;

        span {
            display: block;
        }
    }

    svg {
        fill: #fff;
    }
}
