@import 'variables';

.draft-round {
    font-family: $logo-font-family;
    font-size: 1.4rem;
    color: $primary-border-color;
    line-height: 0.86;
    margin-top: 1.2rem;

    &__round {
        font-weight: bold;
    }
}
