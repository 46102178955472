@import '../../variables.scss';

.league-rules-form {

    .header {
        color: #fff;
    }

    &__rule {
        margin: .4rem 0;

        .form__input {
            margin-top: .6rem;

            &:disabled {
                background: $primary-grey;
                border-color: $secondary-grey;
                color: $primary-dark;
                pointer-events: none;
            }
        }
    }

    &__timelines {
        color: #fff;
        display: flex;
        font-size: 1.2rem;
        font-weight: 400;
        padding: .4rem 0;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__time {
        font-weight: 600;
    }

    &__time-desc {
        text-transform: uppercase;
    }

    &__time-desc, &__time {
        font-size: 1.4rem;
        width: 100%;
    }

    &__scoring {
        margin-top: 1.6rem;

    }

    &__scoring-header {
        font-size: 1.4rem;
    }
}
