@import 'variables';

.error-screen {
    margin-left: 1.6rem;

    &__header {
        display: flex;
        align-items: center;

        svg {
            margin-right: .8rem;
        }
    }

    &__text p {
        font-weight: 500;
        font-size: 1.6rem;
    }

    &__stack code {
        display: block;
        margin-top: 2.4rem;
        max-width: 60rem;
    }
}
