@import '../../variables.scss';

.usp {
    margin-top: 2.4rem;

    &__visuals {
        display: flex;
        justify-content: center;
        position: relative;
        padding-bottom: 3.6rem;
    }

    &__visual {
        position: relative;
        z-index: 1;
    }

    &__visual-bubble {
        position: absolute;
        z-index: 0;

        svg {
            height: 50%;
        }

        &:first-of-type {
            left: 0;
            svg {
                transform: translate(0%,85%);
            }
        }

        &:last-of-type {
            right: 0;
            svg {
                transform: translate(0%,25%);
            }

        }
    }

    picture {
        display: block;
        overflow: hidden;
    }

    &__main-image {
        height: 34.7rem;
    }

    &__teaser-image {
        margin-top: 3.2rem;
        width: 100%;
        max-height: 35.2rem;
    }

    &__explainers {
        padding-bottom: 6rem;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 1.6rem;
    }

    &__footer-links {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 2rem;

        a {
            font-size: 1.4rem;
            margin: 1.6rem 0;
            text-decoration: none;
            color: #fff;
        }
    }
}

.usp-explainer-badge {
    display: none;
}

.usp-logo {
    color: #fff;
    font-family: $logo-font-family;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: .75;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: .4rem;
    }
}

.usp-reg-box {
    background-image: linear-gradient(to top, #1a143f, #32103f);
    border-radius: 0 5rem 0 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.4rem 3.2rem;
    margin: 0 1.6rem;
    text-align: center;

    &__header {
        color: #fff;
        font-family: $logo-font-family;
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1.21;
        letter-spacing: -0.056rem;
        margin: 0;
    }

    &__text {
        color: $secondary-grey;
        font-size: 1.6rem;
        line-height: 1.38;
        margin: 1.6rem 0;
    }

    &__cta {
        text-decoration: none;
        padding: 1.6rem 0;

        .button {
            background: $primary-purple;
            justify-content: center;
            margin: 0 auto;
        }
    }
}

@media screen and (min-width: 768px) {
    .usp {
        max-width: 128rem;
        margin: 0 auto;

        &__explainer {
            margin: .8rem 1.6rem;
        }


        &__teaser-image {
            max-height: none;
        }
    }


}

@media screen and (min-width: 1024px) {
    .usp {

        &__explainer-set {
            display: grid;
            grid-gap: .8rem;
            grid-template-columns: repeat(auto-fit, minmax(24rem,1fr));

            .explainer {
                min-height: 27.8rem;

                &__text {
                    margin: 0;
                }

                .header {
                    width: auto;
                }
            }
        }

        &__main-image {
            height: 55rem;
        }
    }

    .usp-explainer-badge {
        display: block;
        border: .1rem solid;
        box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.06);
        margin: 1.6rem;

        &--primary {
            border-color: $primary-blue;
            border-radius: 10rem 0 0 0;
        }

        &--secondary {
            border-color: $primary-purple;
            border-radius: 10rem 0 10rem 0;
            order: 2;
        }

        &--tertiary {
            border-color: $primary-pink;
            border-radius: 0 0 50% 50%;
        }
    }

    .usp-reg-box {
        text-align: left;
        margin-top: 8.8rem;
        flex-direction: row;
        justify-content: space-between;

        &__text {
            margin: .8rem 0;
        }
    }
}
