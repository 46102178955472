@import 'variables';

.rules {
    /* TODO: Make this global */

    &__column {
        &:last-of-type {
            display: none;
        }
    }

    &__core {
        display: flex;
        margin: 1.6rem;

        p {
            font-size: 1.4rem;
            line-height: 1.57;
        }
    }

    &__content {
        margin-bottom: 4rem;
    }

    &__jump-to {
        border-radius: 0 0 25% 0;
        background: #f7f8fb;
        color: $primary-dark;
        min-width: 31rem;
        max-height: 42rem;
        margin-left: 3.2rem;

        ul {
            list-style: none;
            padding: 0;
        }

        li {
            position: relative;
            margin-left: 1.6rem;
            padding: 1.2rem 0;
            cursor: pointer;

            &:hover {
                svg {
                    transform: translateY(.2rem);
                    transition: transform .15s ease-in-out;
                }
            }
            &:after {
                position: absolute;
                content: '';
                bottom: 0;
                left: -1.6rem;
                right: 0;
                height: .1rem;
                background: #e7e7ea;
            }

            &:first-of-type {
                font-size: 1.6rem;
                font-weight: bold;
            }

            span {
                font-size: 1.6rem;
                font-weight: 600;
            }

            svg {
                float: right;
                margin-right: 1.6rem;
                height: 1.2rem;
                width: 1.2rem;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .rules {

        &__core {
            margin-right: 0;
        }

        &__column {
            &:last-of-type {
                display: block;
                flex: 1 0 auto;
            }
        }

        &__quicklinks {
            display: none;
        }
    }
}
