@import 'variables';

.button {
    border: none;
    border-radius: .5rem;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    padding: .9rem 1rem;
    text-transform: capitalize;

    &:disabled {
        background: $secondary-grey;
        cursor: default;
        user-select: none;
    }

    &__svg {
        width: .6rem;
        fill: #fff;

        &--left {
            margin-left: .2rem;
            margin-right: .8rem;
        }

        &--right {
            margin-left: .8rem;
            margin-right: .2rem;
        }
    }

    &--primary {
        background: $primary-purple;
    }

    &--secondary, &--commissioner {
        background: $primary-pink;
    }

    &--tertiary, &--commissioner  {
        display: block;
        padding: .8rem;
        width: 100%;
    }

    &--tertiary {
        background: $primary-purple;
    }

    &--quaternary {
        background: $secondary-purple;
    }

    &--bgless {
        background: transparent;
        border: .2rem solid #fff;
        max-height: 3.4rem;
    }

    &--directional {
        background: $secondary-dark;
        font-size: 1.2rem;
        font-weight: bold;
        border-radius: .3rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    }

    &--skip-link {
        background: none;
        color: #fff;
        margin: 0 auto;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: .03rem;
        margin-top: .4rem;
    }

    &--submit {
        background: $primary-pink;
        border-radius: .2rem;
        width: 100%;
        text-transform: capitalize;
        justify-content: center;
    }

    &--auth {
        background: rgba(255, 255, 255, 0.15);
        justify-content: center;
        padding: 1.4rem;
        position: relative;

        svg {
            margin: 0;
            right: 1.8rem;
            position: absolute;
        }
    }
}

@media screen and (min-width: 768px) {
    .button {
        transition: background .25s ease-in;

        &--primary {
            &:hover:not([disabled]) {
                background: $primary-purple-hover;
            }
        }

        &--secondary {
            &:hover:not([disabled]) {
                background: $primary-pink-hover;
            }
        }

        &--submit, &--tertiary {
            &:hover:not([disabled]) {
                background: $secondary-purple-hover;
            }
        }

        &--quaternary, &--bgless {
            padding: 1rem 8rem;
        }
    }
}
