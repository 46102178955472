@import 'variables';

.player-score-board {
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: .5rem;
    color: #fff;
    text-align: center;
    max-width: 16.8rem;
    width: 75%;
    margin: 0 .4rem;

    &__description, &__points {
        margin: 0;
    }

    &__description {
        font-size: 1.2rem;
        line-height: 1;
        padding: 1.6rem 0 .8rem;
        text-transform: capitalize;
   }

    &__points {
        height: 4.8rem;
        font-size: 3.2rem;
        font-weight: 800;
        line-height: 1;
    }
}
