@import 'variables';

.nfl-score {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
    border: solid .1rem $primary-border-color;
    border-radius: .3rem;
    margin: .4rem .8rem;
    padding: 1.2rem 0;

    &__team-data {
        display: flex;
        align-items: center;
    }

    &__team-nickname {
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: .1rem;
        min-width: 9rem;
        width: 9rem;
        color: $primary-dark;
        text-transform: uppercase;

        &--away {
            text-align: right;
        }
    }

    &__team svg, &__team img {
        height: 2.6rem;
        width: 2.6rem;
        margin: 0 .8rem;
    }

    &__score-container, &__time-container {
        width: 7rem;
        height: 3.2rem;
        border-radius: .5rem;
        box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
        border: .1rem solid $border-color;
    }

    &__score-container {
        display: flex;
        align-items: center;
    }

    &__time-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__time {
        font-size: 1.5rem;
    }

    &__score {
        color: $primary-dark;
        font-size: 1.5rem;
        font-weight: 800;
        margin: 0 .6rem;
        text-align: center;
        width: 3.2rem
    }

    &__score-separator {
        width: .1rem;
        height: calc(100% - .4rem);
        background: #434343;
    }

    &--complete {

        .nfl-score {
            &__score-container {
                background: $secondary-dark;
            }

            &__score {
                color: #fff;
            }

            &__score-separator {
                background: #434343;
            }
        }
    }

    &--live {
        padding-bottom: .6rem;

        .nfl-score {
            &__clock-data {
                position: relative;
                left: -.4rem;
                font-weight: bold;
                margin-top: .6rem;
            }

            &__score-container {
                border: solid .1rem $primary-pink;
                background-color: rgba(224, 13, 131, 0.1);
            }

            &__score-separator {
                background: $primary-pink;
            }
        }
    }
}

@media screen and (min-width: 480px) {
    .nfl-score {
        &__team-nickname {
            font-size: 1.4rem;
            min-width: 13rem;
            width: auto;
        }

        &__team svg, &__team img {
            height: 3.2rem;
            width: 3.2rem;
        }
    }
}

@media screen and (max-width: 355px) {
    .nfl-score {
        &__team-nickname {
            font-size: 1rem;
        }

        &__team svg, &__team img {
            height: 2rem;
            width: 2rem;
        }
    }
}
