@import 'variables';

.draft-board {
    min-width: 81.8rem; // TODO this needs to be dynamic based on number of users - this works for 8
    &__header {
        display: flex;
        position: sticky;
        top: 3rem;
        background: #f3f6f7;
        margin-left: 1.8rem;
    }

    &__user-header {
        color: $secondary-grey;
        display: flex;
        justify-content: center;
        font-size: 1.4rem;
        font-weight: 600;
        margin: 2rem 0 .8rem;
        text-align: center;
        width: 100%;
    }

    &__explainer {
        margin: auto 3.2rem;
        padding-top: 2.4rem;
        text-align: center;

        .header {
            color: $primary-dark;
        }
    }

    &__round {
        display: flex;
        align-items: center;
        margin-bottom: .8rem;
        border-radius: .8rem;
        box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
        border: solid .1rem $primary-border-color;
        background-color: #fff;

        &--picking {
            border-color: $primary-purple;
        }

        &--even {

            .draft-board__round-picks {
                flex-direction: row-reverse;

                .draft-player-card {

                    &:first-of-type {
                        border-left: .1rem solid #f1f1f1;
                    }

                    &:last-of-type {
                        border: none;
                    }
                }
            }
        }
    }

    &__round-number {
        color: $secondary-grey;
        font-size: 1.4rem;
        font-weight: bold;
        transform: rotate(270deg);
        width: 1.8rem;
    }

    &__round-picks {
        display: flex;
        border-left: .1rem solid $primary-border-color;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .draft-board {
        min-width: auto;

        &__round-picks {
            border: 0;
            border-top: .1rem solid $primary-border-color;

        }
        &__round-number {
            position: sticky;
            top: 0;
            transform: rotate(0);
        }
    }
}
