@import 'variables';

.game-day {
    background: linear-gradient(to right, #4e0f69, $primary-purple);

    .league-standings, .h2h-fixtures, .live-scores, .schedule, .perfect-team, .user-next-h2h {
        background: #fff;
        border-radius: .8rem;
        border-color: transparent;

        .header {
            font-size: 2rem !important;
        }
    }
}

@media screen and (min-width: 1024px) {
    .game-day {
        background: linear-gradient(180deg,#18164e,#571855);

        .user-gw-tally {
            margin-bottom: .8rem;
        }

        &__section {
            display: flex;
            justify-content: center;

            > * {
                width: calc(50% - 2.4rem);
                margin: 1.2rem;
                flex: 1 0 auto;
            }

            .visual-separator {
                display: none;
            }
        }
    }
}
