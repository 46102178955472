@import 'variables';

.your-data {
    background: $header-gradient;
    margin-bottom: 1.6rem;

    &__explainer {
        font-size: 1.4rem;
    }

    .hero {
        text-transform: uppercase;
    }
}
