@import '../../variables.scss';

@keyframes unfoldIn {
    0% {
        transform:scaleY(.005) scaleX(0);
    }
    50% {
        transform:scaleY(.005) scaleX(1);
    }
    100% {
        transform:scaleY(1) scaleX(1);
    }
}

@keyframes unfoldOut {
    0% {
        transform:scaleY(1) scaleX(1);
    }
    50% {
        transform:scaleY(.005) scaleX(1);
    }
    100% {
        transform:scaleY(.005) scaleX(0);
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(21, 25, 29, 0.9);
    z-index: 10;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center; /* aligns on vertical for column */
    align-items: center;
    transform:scaleY(.01) scaleX(0);
    animation:unfoldIn .4s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

    &--favourite-team {
        top: $nav-height;
        z-index: 5;
    }

    > * {
        -webkit-overflow-scrolling: touch;
    }
}

// Animation of modal taken from https://codepen.io/designcouch/pen/obvKxm
body.animate-modal-out {
    .modal {
        transform: scale(1);
        animation:unfoldOut .4s .1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
}
