@import 'variables';

.round-record {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    padding-bottom: 2rem;
    border-right: .1rem solid $primary-border-color;

    &:last-of-type {
        border-right: none;
    }

    &__description {
        font-size: 1.2rem;
        text-transform: capitalize;
    }

    &__value {
        font-size: 2.4rem;
        font-weight: bold;
        color: $primary-pink;
    }
}
