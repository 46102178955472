@import 'variables';

.player-box {
    background: #fff;
    border: 1px solid transparent;
    border-radius: .3rem;
    box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.6rem;
    margin: .4rem 0;
    padding: 0 .8rem;
    transition: border-color .15s ease-in;

    &--sub-view & {
        &__name {
            font-weight: bold;
        }

        &__point-wrapper {
            &:before {
                content: none;
            }
        }
    }

    &--next-h2h-view {
        justify-content: flex-start;
    }

    &--ineligible {
        opacity: .3;
        pointer-events: none;
        touch-action: none;
    }

    &--sub-locked {
        border-color: $border-color;
    }

    &:hover {
        border-color: $primary-purple;
    }

    &__draft-pick {
        color: $primary-pink;
        font-size: 1.6rem;
        font-weight: 600;
        margin: 0 .8rem;
        padding-right: .8rem;
    }

    &__point-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__name {
        font-size: 1.6rem;
        font-weight: 600;
        margin: 0;
    }

    &__opposition {
        color: $primary-dark;
        font-size: 1.4rem;
        font-weight: bold;
    }

    &__perfect-icon {
        background: $primary-purple;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: .6rem;
        width: 1.6rem;
        height: 1.6rem;
    }

    &__time {
        color: $primary-dark;
        font-size: 1.2rem;
        font-weight: 500;
    }

    &__opposition-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__opposition-wrapper, &__point-wrapper {
        min-width: 7.2rem;
        position: relative;

        &::before {
            background-color: $border-color;
            position: absolute;
            content: '';
            width: .1rem;
            height: 5rem;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }

    &__points {
        color: $primary-dark;
        font-size: 1.6rem;
        font-weight: bold;
        text-align: center;
    }

    &__points-description {
        color: $secondary-grey;
        font-weight: 600;
    }
}
