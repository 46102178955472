@import 'variables';

.favourite-team {
    display: flex;
    flex-direction: column;
    background: $home-gradient;
    height: 100%;
    width: 100%;

    .hero--join-journey {
        height: 24rem;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas: ". . .";
        grid-gap: .6rem;
        margin: .8rem 1.2rem 0;
        overflow-y: auto;
        padding-top: 1.2rem;
    }

    &__cta {
        margin-top: auto;
        padding: 2.4rem 1.2rem;
    }
}

.team {
    border: .2rem solid transparent;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1.2rem 0;
    transition: border-color ease-in-out .25s;

    &[data-is-selected="true"] {
        border-color: $primary-blue;
    }

    &__logo {
        height: 4.8rem;
        width: 4.8rem;
        margin: 0 auto;
        pointer-events: none;

        img {
            height: 4.8rem;
            width: 4.8rem;
        }
    }

    &__name {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
    .favourite-team {
        &__grid {
            grid-template-columns: repeat(4, 1fr);
            grid-template-areas: ". . . .";
        }
    }

    .team {
        cursor: pointer;

        &:hover {
            border-color: $primary-blue;
        }
    }
}


@media screen and (min-width: 1280px) {
    .favourite-team {
        &__grid {
            grid-template-columns: repeat(5, 1fr);
            grid-template-areas: ". . . . .";
        }
    }
}
