@import 'variables';

.h2h-roster {
    background: #fff;
    z-index: 1;
    height: 100%;
    position: relative;

    &__toggle {
        padding: .8rem 0;
        position: sticky;
        top: 0;
        z-index: 1;
        background: #fff;

        .toggle {
            &--userTeam {
                margin-top: .4rem;
            }

            &__option {
                height: 3.4rem;
                width: 50%;
                flex: unset;
            }
        }
    }

    &__players {
        width: calc(100% - .8rem);
        margin: 0 .4rem;
    }

    &__wrapper {
        display: flex;
        justify-content: space-around;
        margin: 0 1.6rem;
        height: 120rem;
    }
}
