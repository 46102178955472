@import 'variables';

.waiver-toggle {
    margin: 0 1.6rem;

    &__list {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
    }

    &__list-item {
        background-color: rgba(255, 255, 255, 0.15);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        height: 4.4rem;
        flex: 1 0 auto;
        margin: 0 .4rem;
        cursor: pointer;
        z-index: 1;

        &:first-of-type {
            border-top-left-radius: 1.6rem;
        }

        &--active {
            background-color: #fff;
            color: $primary-dark;
            font-weight: 600;
        }
    }
}


@media screen and (min-width:768px) {
    .waiver-toggle {

        &__list-item {
            transition: color .25s ease-in, background .25s ease-in;

            &:hover {
                background: $secondary-purple;
                color: #fff;
            }
        }
    }
}
