@import 'variables';

.draft-priority-modal {
    background: #fff;
    height: 50vh;
    min-height: 45rem;
    width: 50vw;
    min-width: 65rem;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1.6rem;
    z-index: 20;


    &__close-container {
        background: #fff;
        margin-left: auto;
        width: 3.4rem;
        height: 3.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .3rem;
        box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.08);
        cursor: pointer;
        margin-bottom: 1.2rem;
    }

    &__header {
        border-radius: .5rem;
        text-align: center;
    }

    &__explainer {
        color: $primary-dark;
        font-size: 1.6rem;
        font-weight: 300;
        margin: 0 1.6rem 2.4rem;

    }

    &__user-list {
        overflow-y: auto;
        border: none;
        height: calc(100% - 14rem);

        .button {
            margin-top: auto;
            font-size: 1.6rem;
            padding: 1.2rem;
        }
    }
}

@media screen and (min-width: 768px) {

    .draft-priority-modal {

        &__close-container {
            transition: background .25s ease-in;

            &:hover {
                background: #a4a4a4;
            }

        }
    }
}
