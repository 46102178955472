@import '../../variables.scss';

.header {
    color: $primary-dark;
    text-transform: capitalize;
    font-weight: bold;

    &--primary, &--primary-alternate {
        font-size: 2.2rem;
        user-select: none;
        margin: 2.4rem 0;

        span {
            font-size: 2.2rem;
            font-weight: normal;
        }
    }

    &--primary-alternate {
        color: #fff;
        text-shadow: 0 .2rem .4rem rgba(0, 0, 0, 0.18);
        letter-spacing: .1rem;
    }

    &--sub {
        font-size: 1.8rem;
        font-weight: 500;
        margin: 2.4rem 0 .8rem;
    }

    &--mini {
        font-size: 1.4rem;
        text-transform: uppercase;
    }
}
