@import 'variables';

.stats-container {

    &__header {
        position: relative;
        height: 9.2rem;
        background: $header-gradient;

        .header {
            color: #fff;
            margin: 0;
            padding: 3.6rem 1.6rem ;
            text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
            font-family: $logo-font-family;
            font-size: 3.6rem;
            font-weight: bold;
            line-height: 0.73;
            letter-spacing: -0.072rem;
        }
    }

    &__bubbles {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;
        svg {
            position: relative;
            right: -3rem;
        }
    }

    &__dropdown, .player-pool__header {
        padding: 1.6rem 1.6rem 0.4rem 1.6rem;
        background: $primary-grey;
    }

    &__content {

        .stat-player-pool {
            margin-top: 0;
        }

    }

    /* TODO: taken from roster */
    &__botw-explainer {
        color: $primary-dark;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        letter-spacing: .05rem;
        text-align: right;
        padding: .8rem;

        span {
            line-height: 1;
            margin-right: .6rem;
        }

    }

    /* TODO: taken from roster */
    &__svg-wrapper {
        position: relative;
        background: $border-color;
        border-radius: 50%;
        width: 2.4rem;
        height: 2.4rem;

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

}
