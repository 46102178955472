@import 'variables';

.player-modal {
    height: 100%;
    width: 100vw;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;

    &--player-pane & {
        &__player-pane {
            transform: translate3d(0, 0, 0);
        }

        &__secondary-pane, &__tertiary-pane {
            transform: translate3d(calc(100% + 1.6rem), 0, 0);
        }

        &__tertiary-pane {
            display: none;
        }
    }

    &--secondary-pane & {
        &__player-pane {
            transform: translate3d(calc(-100% - 1.6rem), 0, 0);
        }

        &__secondary-pane {
            transform: translate3d(0, 0, 0);
        }

        &__tertiary-pane {
            display: block;
            transform: translate3d(calc(100% + 1.6rem), 0, 0);
        }

        &__close-container {
            display: none;
        }
    }

    &--tertiary-pane & {
        &__player-pane {
            transform: translate3d(calc(-200% - 1.6rem), 0, 0);
        }

        &__secondary-pane {
            transform: translate3d(calc(-100% - 1.6rem), 0, 0);
        }

        &--tertiary-pane {
            transform: translate3d(0, 0, 0);
        }

        &__close-container {
            display: none;
        }
    }

    &--initial-gw {
        .player-button {
            &--waiver, &--trade {
                opacity: .3;
                pointer-events: none;
            }
        }
    }

    &--has-injury & {
        &__header {
            padding-top: 2.4rem;
        }

        &__points-wrapper {
            margin-top: 1.6rem;

            .player-score-board__points {
                height: 4rem;
            }
        }

        &__injury-status {
            padding: 1.6rem 0;
        }

        &__owner-container {
            padding: 0 0 1.6rem 0;
        }

        &__stats {
            height: calc(100% - 28.5rem);
        }
    }

    &__pane-container {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        overflow: hidden;
    }

    &__secondary-pane {
        background: #fff;
    }

    &__player-pane, &__secondary-pane {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: transform .15s ease-out;
    }

    &__close-container {
        margin-left: auto;
        margin-right: 1.6rem;
        margin-top: 1.6rem;
        width: 3rem;
        height: 3rem;
        display: flex;
        cursor: pointer;
        position: relative;
        z-index: 1;
    }

    &__header {
        background-image: $background-gradient;
        padding-top: 3.6rem;

        &--ATL {
            background-image: $ATL;
        }

        &--ARI {
            background-image: $ARI;
        }

        &--BAL {
            background-image: $BAL;
        }

        &--BUF {
            background-image: $BUF;
        }

        &--CAR {
            background-image: $CAR;
        }

        &--CHI {
            background-image: $CHI;
        }

        &--CIN {
            background-image: $CIN;
        }

        &--CLE {
            background-image: $CLE;
        }

        &--DAL {
            background-image: $DAL;
        }

        &--DEN {
            background-image: $DEN;
        }

        &--DET {
            background-image: $DET;
        }

        &--GB {
            background-image: $GB;
        }

        &--HOU {
            background-image: $HOU;
        }

        &--IND {
            background-image: $JAX;
        }

        &--JAX {
            background-image: $JAX;
        }

        &--KC {
            background-image: $KC;
        }

        &--LA {
            background-image: $LA;
        }

        &--LAC {
            background-image: $LAC;
        }

        &--LV, &--OAK {
            background-image: $LV;
        }

        &--MIA {
            background-image: $MIA;
        }

        &--MIN {
            background-image: $MIN;
        }

        &--NE {
            background-image: $NE;
        }

        &--NO {
            background-image: $NO;
        }

        &--NYG {
            background-image: $NYG;
        }

        &--NYJ {
            background-image: $NYJ;
        }

        &--PHI {
            background-image: $PHI;
        }

        &--PIT {
            background-image: $PIT;
        }

        &--SF {
            background-image: $SF;
        }

        &--SEA {
            background-image: $SEA;
        }

        &--TB {
            background-image: $TB;
        }

        &--TEN {
            background-image: $TEN;
        }

        &--WAS {
            background-image: $WAS;
        }
    }

    &__player-name {
        margin: 0 0 1.2rem 0;
        font-size: 3.8rem;
        font-weight: 600;
        line-height: .83;
        color: #fff;
        text-align: center;
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
    }

    &__player-first-name {
        display: block;
        font-size: 2.4rem;
        font-weight: 300;
        padding-bottom: .8rem;
    }

    &__points-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.4rem;
    }

    &__injury-status {
        text-align: center;
    }

    &__owner-container {
        color: #fff;
        font-size: 1.4rem;
        padding: 1.6rem 0;
        text-align: center;
    }

    &__owner {
        font-size: 1.4rem;
        font-weight: 600;
    }

    &__stats {
        background: #fff;
        border-radius: 1.2rem 1.2rem 0 0;
        display: flex;
        flex-direction: column;
        height: calc(100% - 27.5rem); // Header is 27.5rem & injury state is 4.6rem
        padding: 1.6rem;
    }

    .player-actions {
        margin-top: auto;
    }

    .stat-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: ". .";
        min-height: 10rem;
        overflow-y: auto;

        &__empty-stat {
            align-self: center;
            text-align: center;
            font-weight: bold;
            font-size: 1.4rem;
            width: 200%;
        }
    }

    .player-stat {
        padding: 1.6rem 0;
    }

    .secondary-pane, .tertiary-pane {
        background: #f3f6f7;
        margin: .4rem;
        overflow-y: auto;

        &__back {
            margin-top: 3rem;

            .button {
                background: none;
                color: $primary-dark;
                margin-left: .4rem;

                svg {
                    width: 1.6rem;
                    height: 1.6rem;
                    fill: $primary-dark;
                }
            }
        }

        &__header {
            margin: 0 .8rem;
        }


    }

    .secondary-pane {
        &__player-list {
            background: #f3f6f7;
            margin: 0 .4rem;

            &:last-of-type {
                padding-bottom: 5rem;
            }
        }
    }

    .tertiary-pane {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .player-modal {
        height: 62.6rem;
        width: 52rem;

        &__pane-container {
            border-radius: 1.2rem;
        }

        &__player-pane, &__secondary-pane {
            background: none;
        }

        .secondary-pane {
            &__back {
                margin-top: 0;
            }
        }

        .stat-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            grid-template-areas: ". . .";
            min-height: 10rem;

            &__empty-stat {
                align-self: center;
                text-align: center;
                font-weight: bold;
                font-size: 1.4rem;
                width: 200%;
            }
        }
    }
}
