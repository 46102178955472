@import 'variables';

.injury-status {
    display: inline-block;

    &--show-status {
        background: #fff;
        border-radius: 4rem;
        display: inline-flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 0.83;
        height: 2.6rem;
        padding: 0 .8rem;

        .injury-status__icon {
            margin-left: 0;
            line-height: unset;
        }
    }

    &--out {
        color: #ff1717;
    }

    &--doubtful {
        color: #ff8946;
    }

    &--questionable {
        color: #0032e3;
    }

    &__icon {
        display: inline-block;
        vertical-align: text-bottom;
        line-height: 1;
        margin-left: .4rem;

        svg {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    &__status {
        font-size: 1.2rem;
        font-weight: bold;
        margin-left: .4rem;
    }
}
