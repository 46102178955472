@import 'variables';

.name-edit {
    border-radius: .8rem;
    width: 50%;
    min-width: 30rem;
    padding: 1.2rem 3.2rem;
    background: $primary-grey;

    &--team & {

        &__form-input {
            margin: .8rem 0;
        }
        &__label {
            font-size: 1.4rem;
            font-weight: 500;
        }

        &__input {
            margin: .4rem 0;
            width: 100%;
        }
    }

    &__close-container {
        //background: $secondary-grey;
        margin-left: auto;
        width: 3.4rem;
        height: 3.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 1.2rem;

        svg, circle, rect {
            stroke: $primary-dark !important;
        }
    }

    &__header {
        display: flex;
        align-iems: center;
        justify-content: space-between;
    }

    &__input, &__label {
        font-size: 1.6rem;
    }

    &__label {
        font-weight: bold;
        text-transform: uppercase;
    }

    &__input {
        margin-left: .8rem;
        padding-left: .4rem;
    }

    .header {
        margin-bottom: 2rem;
    }

    .button {
        margin-top: 2.4rem;
        padding-bottom: 1rem;
    }
}
