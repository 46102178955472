@import 'variables';

.empty-state {
    margin: 0 10%;
    padding: 1.6rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
        display: inline-block;
        border-radius: 50%;
        background: $secondary-border-color;
    }

    &__heading {
        font-size: 2rem;
        font-weight: bold;
        margin: 1.6rem 0;
        text-align: center;
    }

    &__secondary-text {
        display: inline-block;
        font-size: 1.2rem;
        margin: 0 2.4rem;
        text-align: center;
    }
}
