@import '../../variables.scss';

.spinner-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__container {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .2);
        z-index: 10;
    }

}
