@import 'variables';

.countdown {
    padding: 2.4rem 0;
    width: 100%;
    background-image: $header-gradient;

    &--alternate {
        background-image: none;
        padding: 0;

        .countdown {
            &__header {
                svg {
                    display: none;
                }
            }

            &__header-text, &__reminder-text, &__reminder-text--secondary {
                font-size: 1.2rem;
            }

            &__reminder-text {
                padding-top: 1.6rem;
            }
        }
    }

    &--draft-mode {
        background-image: none;
        padding: 0;
        margin-bottom: 1.4rem;

        .countdown {
            &__core {
                margin-top: 0;
            }

            &__reminder-text {
                display: none;
            }

            &__section {
                height: 4.8rem;
            }
        }
    }

    &--warn & {
        &__section {
            &--minutes span, &--seconds span {
                color: #FF7D18;
            }
        }
    }

    &--danger & {
        &__section {
            &--minutes span, &--seconds span {
                color: #FF3838;
            }
        }
    }

    &--opener & {
        &__reminder-text {
            padding-top: 0;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__header-text {
        color: #fff;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: .032rem;
        padding: 0 .8rem;
        text-transform: capitalize;
    }

    &__core {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.6rem;
    }

    &__section {
        background: linear-gradient($secondary-dark,$secondary-dark) padding-box, linear-gradient(to top, $primary-purple, $secondary-purple) border-box;
        border: .1rem solid transparent;
        border-radius: 1rem 0 1rem 0;
        box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.14);
        margin: 0 .2rem;
        min-width: 6rem;
        padding: .8rem;
        text-align: center;
    }

    &__section-text {
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
        font-size: 3rem;
        font-weight: 800;
        color: #fff;
    }

    &__section-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 .6rem;
    }

    &__text {
        color: #fff;
        font-size: 1.3rem;
        font-weight: bold;
        padding-top: .6rem;
        text-align: center;
        text-transform: capitalize;
    }

    &__wrapper {

        &--expired {

            .countdown__section-text {
                font-weight: 200;
            }
        }
    }

    &__reminder-text {
        color: #fff;
        font-size: 1.6rem;
        letter-spacing: 0.032rem;
        padding-top: 2.4rem;
        text-align: center;

        &--secondary {
            font-size: 1.6rem;
            font-weight: bold;
            letter-spacing: normal;
        }
    }
}
