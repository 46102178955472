@import '../../variables.scss';

.player-stat {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-dark;
    padding: 2.4rem 0;

    &__icon {
        width: 3.8rem;
        height: 3.8rem;

        svg {
            width: 3.8rem;
            height: 3.8rem;
        }
    }

    &__description-wrapper {
        margin: 0 1.2rem;
    }

    &__text {
        font-size: 1.2rem;
    }

    &__value {
        font-size: 3.2rem;
        font-weight: 800;
        line-height: .81;
        padding-top: .4rem;
    }
}
