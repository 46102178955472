@import 'variables';

.h2h-modal {
    width: 100%;
    height: 100%;
    background: $navigation-gradient;

    &--limited-view & {

        &__bubbles {
            svg:first-of-type {
                top: 3.6rem;
            }
        }

        &__team-delta, &__team-info {
            padding-bottom: 0;
        }

        &__record {
            display: none;
        }

        &__score {
            margin-bottom: 1.6rem;
        }

        .win-percentage {
            padding-top: .8rem;
        }

    }

    &--past-week & {
        &__yet-to-play-wrapper {
            display: none;
        }
    }

    &__bubbles {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;

        svg {
            position: relative;

            &:first-of-type {
                top: 5rem;
                align-self: center;
            }

            &:last-of-type {
                opacity: .5;
                top: 25%;
            }
        }
    }

    &__close-container {
        position: absolute;
        right: 1.6rem;
        top: 1.6rem;
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        cursor: pointer;
        z-index: 1;
    }

    &__header-wrapper {
        position: relative;
    }

    &__icon-wrapper {
        padding-top: 3rem;
        margin: 0 auto;
        width: 13.6rem;
    }

    &__team-info, &__team-delta {
        display: flex;
        justify-content: center;
        padding: 0 1.6rem;
    }

    &__team-info {
        flex-direction: column;
    }

    &__yet-to-play-wrapper {
        padding-bottom: 1.6rem;
    }

    &__team-names, &__team-delta, &__yet-to-play-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: ". .";
        grid-gap: 3.2rem;
    }

    &__roster-wrapper {
        border-radius: .5rem;
        background: #fff;
        overflow-y: auto;
        height: 100%;
        margin-bottom: 1.2rem;
        z-index: 1;
        position: relative;
    }

    &__team-names, &__team-delta, &__yet-to-play {

        &--home {
            justify-self: flex-end;
            padding-right: 0;
            text-align: right;
        }

        &--away {
            justify-self: flex-start;
            padding-left: 0;
        }
    }

    &__team {

        &--home {
            justify-self: flex-end;
            text-align: right;
        }

        &--away {
            justify-self: flex-start;
        }

        span {
            display: block;
        }
    }

    &__team-name {
        color: #fff;
        font-family: $logo-font-family;
        font-size: 2.2rem;
        max-width: 16rem;
        display: block;

        &:first-of-type {
            justify-self: flex-end;
            text-align: right;
        }

        &:last-of-type {
            justify-self: flex-start;
        }
    }

    &__manager-name {
        color: $secondary-grey;
        font-size: 1.2rem;
    }

    &__score {
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
        font-size: 3.2rem;
        font-weight: 800;
        line-height: 0.75;
        color: #fff;
        margin: 1.6rem 0 0;

        &--previous {
            font-size: 1rem;
            opacity: .75;
            margin: 0 0 0.5rem 0;
        }
    }

    &__record {
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 0.86;
        color: #fff;
        padding-top: 1.6rem;
    }

    &__yet-to-play {
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.06);
        font-size: 1.2rem;
        line-height: 0.83;
        color: #fff;

        &-value {
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 374px) {
    .h2h-modal {

        &__team {
            padding: 0 .8rem;
        }

        &__team-name {
            font-size: 1.6rem;
        }

        &__manager-name {
            font-size: 1rem;
        }
    }
}

@media screen and (min-width: 768px) {
    .h2h-modal {
        height: 62.6rem;
        max-height: 62.6rem;
        width: 52rem;
        overflow-y: hidden;

    }
}


