@import 'variables';

.h2h-player-card {
    background: #fff;
    border-radius: .3rem;
    box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
    border: solid .1rem $secondary-border-color;
    height: 7.5rem;
    margin: .4rem 0;
    padding: 1.2rem;
    position: relative;
    width: 100%;

    &:last-of-type {
        margin-bottom: 1.2rem;
    }

    &__name {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 0.86;
        color: $primary-dark;
    }

    &__score {
        font-size: 1.4rem;
        font-weight: 800;
        line-height: 0.86;
        color: $primary-dark;
        margin-top: 1.2rem;
    }

    &__team-svg {
        position: absolute;
        bottom: 1rem;
        right: 1rem;

        img, svg {
            width: 2.6rem;
            height: 2.6rem;
        }
    }
}
