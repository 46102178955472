@import 'variables';

.season-record {

    &__header {
        font-family: $logo-font-family;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 0.86;
        text-align: center;
        margin: .4rem 0 2.2rem;
    }

    &__results {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
