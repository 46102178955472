@import '../../variables.scss';

.roster {
    padding: .8rem 1.6rem;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;

        h2 {
            font-size: 2rem;
            font-weight: bold;
        }
    }

    &__player-explainer {
        color: #a4a4a4;
        display: flex;
        align-items: center;
        font-weight: bold;
        letter-spacing: .05rem;
        text-align: right;

        span {
            line-height: 1;
            margin-right: .6rem;
            width: 8.4rem;
        }

    }

    &__svg-wrapper {
        position: relative;
        background: $border-color;
        border-radius: 50%;
        width: 2.6rem;
        height: 2.6rem;

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
