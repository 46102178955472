@import '../../variables.scss';

.draft-room {
    background: #f3f6f7;
    display: flex;
    justify-content: space-between;
    padding: 2.4rem;

    &__board {
        width: 100%;
    }

    &__team .draft-team, &__board {
        max-height: calc(100vh - 15rem);
        overflow-y: auto;
    }

    &__panel-header {
        background: #f3f6f7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.4rem;
        padding-bottom: .8rem;
        position: sticky;
        top: 0;
        z-index: 1;

        .header {
            font-size: 2.8rem;
            font-weight: 600;
            margin: 0;
        }
    }

    &__spinner-text {
        color: #fff;
        top: 12rem;
        position: relative;
        text-align: center;
    }

    &__disconnected-text {
        font-size: 3.2rem;
    }

    &__refresh-text {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 1220px) {
    .draft-room {

        .draft-player-pool {
            &__list {
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr 1fr;
                grid-template-areas: '. . .' '. . .' '. . .';

                .draft-player-card {
                    min-width: 13rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1150px) and (min-width: 900px) {
    .draft-room {

        .player-pool {
            &__header .toggle, &__search-box {
                max-width: calc(50% - 1.6rem);
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .draft-room {
        flex-direction: column;
        padding: .8rem;

        &__board {
            box-shadow: 0 0 1rem 0 rgba(0,0,0,.35);
            max-height: calc(100vh - 24rem);
        }

        &__panel-header {
            padding: 1.2rem;
        }

        &__team {
            padding-top: 1.6rem;

            .draft-team {
                background: #fff;
                max-height: 100%;
                margin-left: 0;
                overflow-y: unset;
                width: 100%;

                &__header {
                    background: $header-gradient;
                    border-radius: .5rem .5rem 0 0;

                    .header {
                        color: #fff;
                    }

                    .draft-players-left {
                        background-color: $primary-grey;

                        &__text {
                            color: $primary-dark;
                        }
                    }
                }
            }
        }

        .draft-board {

            &__header {
                display: none;
            }

            &__round {
                flex-direction: column;
                margin-right: 0;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            &__round-number {
                margin: .8rem 0;
            }

            &__round-picks {
                display: block;
                background: transparent;

                .draft-player-card {
                    margin: .8rem 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &--empty {
                        justify-content: center;
                    }

                    &:first-of-type {
                        margin-top: 0;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &__manager-info {
                        display: block;
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    &__first-name, &__last-name {
                        display: inline-block;
                    }

                    &__first-name {
                        line-height: 1.4rem ;
                        vertical-align: top;
                        padding-right: .6rem;
                    }

                    &__last-name {
                        max-width: none;
                    }

                    &__full-position {
                        display: none;
                    }

                    &__manager-info {
                        color: #0c9bf5;
                    }

                    &__misc-info {
                        display: flex;
                        align-items: center;
                        align-self: flex-start;
                        margin-top: .8rem;
                    }

                    &__name {
                        padding-left: .8rem;
                        height: auto;
                    }

                    &__player-info {
                        display: flex;
                        align-items: center;
                    }

                    &__player-team {
                        display: none;
                    }

                    &__position {
                        padding-top: 0;
                        padding-left: .6rem;
                    }

                    &__short-position {
                        display: inline-block;
                    }

                    &__team-name {
                        display: none;
                    }

                    &__team-mobile {
                        display: block;
                    }
                }
            }
        }

        .draft-player-pool {

            &__list {
                display: flex;
                flex-direction: column;
                grid-gap: 0;
            }

            .draft-player-card {
                margin: .8rem;
                width: auto;
            }
        }
    }
}

@media screen and (max-width: 470px) {
    .draft-room {

        &__panel-header {
            margin: 0;
            margin-bottom: 1.2rem;
            padding: .6rem;

            .header {
                font-size: 1.8rem;
            }
        }

        .draft-header {
            padding: 1.2rem;

            &__core {
                align-items: flex-end;
            }

            .countdown {
                margin-bottom: 0;
                &__section {
                    height: 3.2rem;
                    min-width: 3.2rem;
                    padding: .6rem;
                }

                &__section-text {
                    font-size: 1.6rem;
                }

                &__text {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .draft-room {

        .draft-header {
            &__detail-wrapper {
                h1 {
                    font-size: 2.4rem;
                }
            }
        }

        .draft-clock {
            &__on-the-clock, &__up-next {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-size: 1.2rem;
            }

            &__user-name {
                padding: 0;
            }
        }
    }
}
