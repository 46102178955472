@import 'variables';

.auto-draft {
    background: none;
    margin-left: .8rem;
    border: .1rem solid $secondary-grey;
    border-radius: .8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .2rem .8rem;
    cursor: pointer;

    &--saving {
        pointer-events: none;
        opacity: .5;
    }

    svg {
        margin-right: .4rem;
        width: 1rem;
        height: 1rem;
    }
}

@media screen and (min-width: 768px) {
    .auto-draft {
        transition: border-color ease-in .25s;
        &:hover, &:focus {
            border-color: $primary-purple;
            outline: none;
        }
    }
}
