@import '../../variables.scss';

.explainer {
    border-radius: .3rem;
    box-shadow: 0 0 1.2rem 0 rgba(0, 0, 0, 0.06);
    background-color: rgba(255, 255, 255, 0.04);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;
    margin: 1.6rem;

    .header {
        font-family: $logo-font-family;
        font-size: 2.4rem;
        color: #fff;
        letter-spacing: -0.048rem;
        margin: 1.2rem 2.4rem 0.8rem;
        text-align: center;
        width: 23rem;
    }

    &--teaser {
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;

        .explainer {
            &__svg {
                width: auto;
                height: auto;

                svg {
                    margin: 0 .3rem;
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }

            &__text {
                margin: 0 4rem;
            }
        }
    }

    &__svg {
        width: 3.2rem;
        height: 3.2rem;
    }

    &__text {
        color: #fff;
        font-size: 1.2rem;
        line-height: 1.5;
        text-align: center;
    }
}
