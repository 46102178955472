@import 'variables';

.record {
    width: 100%;
    background: #fff;
    border: solid .1rem $primary-grey;
    padding: 2.4rem 2.4rem 1.6rem;

    span {
        display: block;
    }

    &:nth-of-type(2n + 2) {
        .record__name {
            color: $secondary-purple;
        }
    }

    &:nth-of-type(3n + 3) {
        .record__name {
            color: $primary-pink;
        }
    }

    &__icon {
        margin-bottom: .8rem;

        svg {
            width: 6rem;
            height: 6rem;
        }
    }

    &__name {
        color: $primary-blue;
        font-size: 1.8rem;
        font-weight: 600;
    }

    &__manager-name {
        color: $primary-dark;
        font-size: 1.6rem;
        font-weight: 500;
    }

    &__player-name, .player-team-pos-info {
        color: $primary-dark;
        font-size: 1.2rem;
    }

    &__player-name {
        padding-right: .8rem;
    }

    .player-team-pos-info {
        margin: 0;
    }

    &__player-wrapper {
        display: flex;
        align-items: center;
    }

    &__score {
        color: $primary-dark;
        font-family: $logo-font-family;
        font-size: 3.8rem;
        font-weight: bold;
        margin: 1.2rem 0 2.4rem;
    }

    &__time {
        color: $secondary-grey;
        font-size: 1.4rem;
        font-weight: 600;
        margin-top: .4rem;
    }
}
