@import '../../variables.scss';

.standings {
    margin: 0 .8rem;
    .standings-table {

        &__entry {

            &--rank {
                color: $primary-dark;
                font-size: 1.2rem;
            }

            &--gw-points, &--overall-points, &--points, &--record, &--played {
                @include row-emphasis;
            }

            &--rank {
                padding-left: .8rem;
            }

            &--team {
                display: none !important;
            }

            &--shortTeam {
                display: block !important;
            }

            &--gw-points, &--overall-points, &--points, &--played, &--record, &--played, &--benchOfTheWeekWins, &--benchOver100 {
                text-align: center;
                padding-right: .8rem;
            }
        }

        &__header-entry {

            &--gw, &--overall-points, &--points, &--played, &--record, &--benchOver100, &--botw {
                text-align: center;
            }

            &--benchOver100, &--botw {
                width: 8rem;
            }

            &--rank {
                padding-left: .8rem;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .standings {
        .standings-table {
            &__entry {

                &--shortTeam {
                    display: none !important;
                }

                &--team {
                    color: $primary-dark;
                    font-size: 1.2rem;
                    font-weight: bold;
                    max-width: 16.5rem;
                    padding: .4rem 0 !important;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block !important;
                }
            }
        }
    }
}

@media screen and (max-width: 374px) {
    .standings {
        .standings-table {
            &__entry {
                &--split-level {
                    &:first-of-type {
                        left: 0;
                    }
                }
            }
        }
    }
}
