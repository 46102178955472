@import 'variables';

.draft-player-modal {
    height: 100%;
    width: 100vw;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    border-radius: .8rem;
    box-shadow: 0 .2rem 1rem 0 rgba(0, 0, 0, 0.24);
    background: #eee;

    .grey-wrapper {
        height: 100%;
    }

    &__close-container {
        position: absolute;
        right: 1.6rem;
        top: 1.6rem;
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .3rem;
        box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.08);
        cursor: pointer;
        margin: 0;
    }

    &__header {
        background-image: $horizontal-gradient;
        height: 16rem;
        display: flex;
        padding: 1.6rem;
        align-items: center;
        justify-content: space-between;

        > div {
            flex: 1 0 auto;
        }

        &--ATL {
            background-image: $ATL;
        }

        &--ARI {
            background-image: $ARI;
        }

        &--BAL {
            background-image: $BAL;
        }

        &--BUF {
            background-image: $BUF;
        }

        &--CAR {
            background-image: $CAR;
        }

        &--CHI {
            background-image: $CHI;
        }

        &--CIN {
            background-image: $CIN;
        }

        &--CLE {
            background-image: $CLE;
        }

        &--DAL {
            background-image: $DAL;
        }

        &--DEN {
            background-image: $DEN;
        }

        &--DET {
            background-image: $DET;
        }

        &--GB {
            background-image: $GB;
        }

        &--HOU {
            background-image: $HOU;
        }

        &--IND {
            background-image: $JAX;
        }

        &--JAX {
            background-image: $JAX;
        }

        &--KC {
            background-image: $KC;
        }

        &--LA {
            background-image: $LA;
        }

        &--LAC {
            background-image: $LAC;
        }

        &--LV, &--OAK {
            background-image: $LV;
        }

        &--MIA {
            background-image: $MIA;
        }

        &--MIN {
            background-image: $MIN;
        }

        &--NE {
            background-image: $NE;
        }

        &--NO {
            background-image: $NO;
        }

        &--NYG {
            background-image: $NYG;
        }

        &--NYJ {
            background-image: $NYJ;
        }

        &--PHI {
            background-image: $PHI;
        }

        &--PIT {
            background-image: $PIT;
        }

        &--SF {
            background-image: $SF;
        }

        &--SEA {
            background-image: $SEA;
        }

        &--TB {
            background-image: $TB;
        }

        &--TEN {
            background-image: $TEN;
        }

        &--WAS {
            background-image: $WAS;
        }
    }

    &__player-name {
        color: #fff;
        font-size: 1.4rem;

        .player-team-pos-info {
            color: #dadada;
            font-weight: bold;

            &__team-name, &__position {
                font-size: 1.4rem;
            }
        }
    }

    &__player-first-name, &__player-last-name {
        font-size: 2.2rem;
        display: inline-block;
    }

    &__player-last-name {
        font-weight: bold;
    }

    &__player-number[data-number] {
        position:relative;
        height: 100%;

        &:after{
            content: attr(data-number);
            position: absolute;
            height: 100%;
            right: 2.4rem;
            font-size: 18rem;
            top: -40%;
            color: #fff;
            opacity: .2;
        }
    }

    &__personal-stat-wrapper {
        position: relative;
        top: -2.6rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &__personal-stat {
        border-radius: .4rem;
        box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.06);
        border: solid .1rem $border-color;
        background-color: #fff;
        text-align: center;
        padding: 1.4rem;
        width: 33%;
    }

    &__personal-stat-title, &__personal-stat-value {
        font-weight: bold;
    }

    &__personal-stat-title {
        color: #626262;
        font-size: 1.2rem;
    }

    &__personal-stat-value {
        color: $primary-dark;
        font-size: 1.4rem;
    }

    &__season-stats {

        .header {
            margin-top: 0;
            text-align: center;
        }
    }

    &__stats-grid {
        min-height: 20rem;
        overflow-y: auto;
        text-align: center;
        font-weight: bold;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(auto-fill, 11rem);
        margin-bottom: .8rem;


        &--0-stats, &--1-stats {
            grid-template-columns: repeat(1, 1fr);
        }

        &--2-stats {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__draft-action {
        margin: 2.4rem auto 1.2rem;

        .button {
            border-radius: .4rem;
            box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, 0.12);
            background: $primary-purple;
            margin: 0 auto;
            padding: 1.6rem 6rem;
        }
    }

    .player-stat {
        flex: 1 0 auto;

        &--empty {
            font-size: 1.6rem;
        }
    }
}

@media screen and (min-width: 768px) {
    @keyframes zoomIn {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes zoomOut {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    .draft-player-modal {
        height: 75%;
        width: 50%;
        left: auto;
        top: auto;
        min-height: 55rem;
        min-width: 76rem;
        overflow-y: unset;
        transform: scale(0);
        will-change: transform;
        animation: zoomIn .4s .35s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        .grey-wrapper {
            height: auto;
        }

        &__close-container {
            svg {
                transition: transform .25s cubic-bezier(0.165, 0.840, 0.440, 1.000);

            }

            &:hover {
                svg {
                    transform: rotate(90deg);
                }
            }
        }

        &__draft-action {
            .button:hover {
                background: $secondary-purple;
            }
        }

        &__player-first-name, &__player-last-name {
            font-size: 4rem;
        }

        &__personal-stat {
            padding: 2.6rem;
        }

        &__personal-stat-value {
            font-size: 1.6rem;
        }

        &__stats-grid {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(auto-fill, 11rem);
            margin-bottom: .8rem;


            &--0-stats, &--1-stats {
                grid-template-columns: repeat(1, 1fr);
            }

            &--2-stats {
                grid-template-columns: repeat(2, 1fr);
            }

            &--3-stats {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    body.animate-modal-out {
        .draft-player-modal {
            transform: scale(1);
            animation: zoomOut .2s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
    }
}

@media screen and (max-width: 340px) {
    .draft-player-modal {

        &__personal-stat-title, &__personal-stat-value {
            font-size: 1rem;
        }

        &__season-stats {
            .header {
                margin: 0;
            }
        }
    }
}
