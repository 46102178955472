@import 'variables';

.waiver-history-pane {
    margin: .8rem;

    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
