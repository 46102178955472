@import '../variables.scss';

.player-pool {
    margin-top: 1.6rem;

    &--focus & {
        &__search-box {
            border-color: $primary-blue;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        .toggle {
            flex: 1;
            max-width: 42rem;

            &__option {
                height: 4.4rem;
                margin: 0;
            }
        }
    }

    &__label {
        font-size: 1.4rem;
        font-weight: bold;
        margin-left: .8rem;
        margin-bottom: 1.2rem;
        padding-top: 1.6rem;
    }

    &__search-container, &__toggle-container {
        width: 100%;
    }

    &__search-container & {
        &__search-box {
            width: calc(100% - 1.6rem);
            margin: 0 .8rem;

            input {
                margin-left: .8rem;
                width: 100%;
                font-size: 1.6rem;
                border: none;
            }
        }
    }

    &__toggle-container {
        padding-bottom: 1.6rem;

        .toggle {
            border: solid .1rem $border-color;
            box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.08);
            margin: 0 auto;
            max-width: calc(100% - 1.6rem);
        }
    }

    &__search-box {
        display: flex;
        align-items: center;
        border-radius: .3rem;
        box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, 0.06);
        border: solid .1rem $border-color;
        background-color: #fff;
        height: 4.4rem;
        padding: .6rem 0;
        width: 27rem;
        transition: border-color .3s ease-in;

        &:hover {
            border-color: $primary-blue;

            svg {
                fill: $primary-blue;
            }
        }

        label {
            position: relative;

            &::before {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                left: 0;
                width: .1rem;
                background: $border-color
            }
        }

        input {
            &:focus {
                outline: none;
            }
        }

        svg {
            fill: $secondary-grey;
            width: 1.4rem;
            margin: 0 .8rem;
            transition: fill .25s;
        }
    }

    #player-search {
        border: none;
        color: $primary-dark;
        font-size: 1.4rem;
        margin-left: .8rem;
        width: 100%;

        &::placeholder {
            color: #7f7f7f;
        }
    }
}

@media screen and (min-width: 900px) {
    .player-pool {

        &__header {
            flex-direction: row !important;
            padding: 1.6rem 0;
            margin-bottom: .4rem;

            input {
                border: none;
                margin-left: .8rem;
                font-size: 1.6rem;
                width: 100%;
            }
        }

        &__search-box {
            width: 29.5rem;
        }
    }
}
