@import 'variables';

.draft-players-left {
    background-color: rgba(97, 45, 247, 0.12);
    border-radius: 1.6rem;
    padding: .3rem 0;
    width: 13rem;

    &__text {
        color: $primary-purple;
        font-size: 1.1rem;
        font-weight: 600;
        letter-spacing: .044rem;
        text-align: center;
        text-transform: uppercase;
    }
}
