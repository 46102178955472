@import 'variables';

.toggle-switch {
    color: #8c8c8c;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-align: right;
    width: 16rem;

    &__input {
        position: relative;
        -webkit-appearance: none;
        outline: none;
        min-width: 4.8rem;
        height: 2.6rem;
        background-color: #d2d2d2;
        border-radius: 1.3rem;
        margin-left: .8rem;
        transition: background .2s ease-in;

        &:after {
            content: "";
            position: absolute;
            top: .1rem;
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 50%;
            background: white;
            box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.12);
            transform: translate(.2rem, .1rem);
            transition: transform .2s ease-in;
        }

        &:checked {
            background: #26de81;

            &:after {
                transform: translate(2.3rem, .1rem);
                transition: transform .2s ease-out;
            }
        }
    }
}
