@import 'variables';

.gw-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem .8rem;
    border-radius: 1.2rem 1.2rem 0 0;
    background: #fff;

    &__current-gw {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        user-select: none;
    }
}
