@import 'variables';

.user-gw-score-board {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: .8rem;
    box-shadow: 0 .2rem .6rem 0 rgba(0, 0, 0, 0.04);
    border: solid .1rem $primary-border-color;
    margin: 0 .8rem;

    .score-board {
        margin: 1.6rem 0;
        position: relative;
        text-align: center;
        width: 33%;

        &:after {
            position: absolute;
            content: '';
            width: .1rem;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $border-color;
        }

        &:first-of-type {
            &:after {
                display: none;
            }
        }

        &--focal-board {

            .score-board {

                &__points {
                    font-weight: bold;
                }
            }

        }

        &__description, &__points {
            margin: 0;
            color: $primary-dark;
        }

        &__description {
            font-size: 1.2rem;
        }

        &__points {
            font-size: 2.4rem;
            line-height: 1.2;
        }
    }
}
