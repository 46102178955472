@import './variables.scss';
@import './fonts.scss';

* {
    box-sizing: border-box;
    font-family: $base-font-family;
    font-size: 10px;
}

body {
    position: relative;
    margin: 0;
    -webkit-overflow-scrolling: touch;
}

.base {
    min-height: 100vh;

    &--home {
        background: $navigation-gradient;

        .footer {
            color: #fff;
        }
    }
}

.content {
    padding-bottom: 3.2rem;
}
