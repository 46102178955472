@import 'variables';

.stat-player-card {

    &--disabled {
        background: rgba(255, 255, 255, .3) !important;
        opacity: .45;

        .stat-table__fixed-column {
            background: none !important;
            position: relative !important;
        }
    }

    &__player-name {
        font-size: 1.2rem;
        font-weight: bold;
    }

    &__player-info {
        padding: .8rem 0;
        position: relative;
        width: 13rem;

        &:after {
            content: '';
            position: absolute;
            width: .1rem;
            height: 50%;
            top: 50%;
            right: .8rem;
            transform: translateY(-50%);
            background: $border-color;
        }
    }

    &__player-rank {
        text-align: center;

        span {
            color: $primary-purple;
            font-size: 1.4rem;
            font-weight: bold;
            padding: 0 .8rem;
            width: 3.6rem;
        }
    }

    .player-team-pos-info {
        margin-top: .4rem;
    }
}
