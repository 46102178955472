@import '../../variables.scss';

.proposed-waiver-pane {
    margin: .8rem;

    &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__priority-explainer {
        display: flex;
        align-items: center;

        span {
            color: #8c8c8c;
            font-weight: bold;
            margin-right: .6rem;
            text-align: right;
            text-transform: uppercase;
            max-width: 11rem;
        }
    }

    &__svg-wrapper {
        position: relative;
        background: #fff;
        border-radius: 50%;
        width: 2.6rem;
        height: 2.6rem;

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__waivers {
        width: 90%;
        margin: 0 auto;
        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            list-style: none;
        }
    }
}
