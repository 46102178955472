@import 'variables';

.nav-bar {
    background: $navigation-gradient;
    color: #fff;
    cursor: default;
    height: $nav-height;
    position: relative;
    line-height: .85;
    text-transform: capitalize;
    width: 100%;
    z-index: 10;

    &__heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 6.4rem;
        padding: .8rem;
        width: 100%;

        a {
            color: #fff;
            text-decoration: none;
        }
    }

    &__heading {
        display: flex;
        align-items: center;
        font-family: $logo-font-family;
        font-weight: bold;
        font-size: 2.2rem;
        letter-spacing: normal;
        line-height: 0.75;

        svg {
            margin-right: .4rem;
        }
    }

    &__menu-wrapper {
        display: flex;
        align-items: center;
        margin-right: .8rem;
    }

    &__active-link {
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: 0.028rem;
        line-height: 1;
        padding-right: 2.5rem;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            height: $nav-height;
            top: 50%;
            right:0;
            transform: translateY(-50%);
            width: .1rem;
            background-color: rgba(255, 255, 255, .1);
        }
    }

    /** Modified from https://codepen.io/designcouch/pen/Atyop **/
    .burger-menu {
        width: 5rem;
        height: 4.4rem;
        display: flex;
        padding-left: 1.5rem;

        &__wrapper {
            position: relative;
            transform: rotate(0deg);
            transition: .5s ease-in-out;
            cursor: pointer;
            width: 5rem;
            height: 4.4rem;
            top: 25%;
            left: 25%;

            span {
                display: block;
                position: absolute;
                height: .3rem;
                width: 2.4rem;
                background: #fff;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: .25s ease-in-out;

                &:nth-child(1) {
                    top: 0;
                }

                &:nth-child(2), &:nth-child(3) {
                    top: .9rem;
                }

                &:nth-child(4) {
                    top: 1.8rem;
                }

            }
        }
    }

    &__link-wrapper {
        color: #fff;
        letter-spacing: .05rem;
        overflow-y: auto;
        line-height: 2.6;
        padding: 0 .8rem 5.2rem;
        text-transform: uppercase;
    }

    &__link {
        list-style: none;
        position: relative;

        &--active:before {
            position: absolute;
            display: block;
            content: ' ';
            height: 3.4rem;
            width: .6rem;
            top: 50%;
            left: -.8rem;
            transform: translateY(-50%);
            background: #fff;
        }

        a {
            color: #fff;
            display: inline-block;
            font-size: 2rem;
            font-weight: bold;
            text-decoration: none;
            user-select: none;
            width: 100%;
        }

        svg {
            width: 2rem;
            height: 2rem;
            vertical-align: text-bottom;
            margin-left: 1.4rem;
            margin-right: 1rem;
        }
    }

    &__auth-wrapper {
        display: none;
        text-align: center;
        margin: auto 1.6rem 2.4rem 1.6rem;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.15);

        .button {
            width: 100%;
        }
    }

    &__link-content {
        position: fixed;
        display: flex;
        flex-direction: column;
        background: $nav-background-gradient;
        top: 6.4rem;
        bottom: 0;
        width: 100%;
        z-index: 30;
        overflow-y: auto;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform .25s;
    }

    &--open {

        .nav-bar {
            background: $background-gradient;

            &__auth-wrapper {
                display: block;
            }

            &__link-content {
                transform: scaleY(1);
            }
        }

        .burger-menu {

            &__wrapper {

                span {
                    &:nth-child(1), &:nth-child(4) {
                        top: .9rem;
                        width: 0;
                        left: 50%;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    &--home {
        background: transparent;
    }

    .unauthed-menu-link {
        margin: 0 .4rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .nav-bar {
        &__link {

            a {
                display: inline-flex;
                align-items: center;
            }
        }

        &__link-content {
            max-width: 36.5rem;
            right: 0;
        }
    }
}

@media screen and (min-width: 1024px) {
    .nav-bar {

        &__link {
            line-height: 3;
            padding: 0 1rem;

            a {
                font-size: 1.2rem;
                font-weight: 500;
                letter-spacing: 0.028rem;
                text-transform: capitalize;
                width: auto;
            }

            svg {
                display: none;
            }

            &:after {
                position: absolute;
                display: block;
                content: ' ';
                height: .4rem;
                bottom: 0;
                left: .6rem;
                right: .6rem;
                transform: scale(0);
                background: $primary-pink-hover;
                transition: transform .25s ease-in;
            }

            &--active {
                &:before {
                    content: none;
                }

                &:after {
                    transform: scale(1);
                }
            }

            &:hover {
                &:after {
                    transform: scale(1);
                }
            }
        }

        &__link-content {
            position: static;
            background: none;
            transform: scale(1);
        }

        &__link-wrapper {
            display: flex;
            align-items: center;
            line-height: 1;
            padding: 0;
            overflow-y: hidden;
        }

        &__menu-wrapper {
            margin-right: 0;
        }

        &--open {
            .nav-bar__auth-wrapper {
            display: none !important;

            }
        }
    }
}

@media screen and (min-width: 1280px) {
    .nav-bar {

        &__link {
            margin: 0 1.2rem;

            a {
                font-size: 1.4rem;
            }
        }
    }
}
